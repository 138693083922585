// DATA
import { menuDT_enUS as menuDT_home } from "./data/home/menuDT_en-us";
import { bannerDT_enUS as bannerDT_home } from "./data/home/bannerDT_en-us";
import { explorerDT_enUS as explorerDT_home } from "./data/home/explorerDT_en-us";
import { servicesDT_enUS as servicesDT_home } from "./data/home/servicesDT_en-us";
import { technologiesDT_enUS as technologiesDT_home } from "./data/home/technologiesDT_en-us";
import { contactDT_enUS as contactDT_home } from "./data/home/contactDT_en-us";
import { footerDT_enUS as footerDT_home } from "./data/home/footerDT_en-us";
import { contentDT_enUS as contentDT_signIn } from "./data/sign-in/contentDT_en-us";
import { contentDT_enUS as contentDT_platform } from "./data/platform/contentDT_en-us";
import { contentDT_enUS as formDT_users } from "./data/users/contentDT_en-us";
import { inputsDT_enUS as inputsDT_common } from "./data/common/inputsDT_en-us";
import { requestDT_enUS as requestDT_common } from "./data/common/requestDT_en-us";
import { deleteDT_enUS as deleteDT_common } from "./data/common/deleteDT_en-us";
import { contentDT_enUS as formDT_contacts } from "./data/contacts/contentDT_en-us";
import { formDT_enUS as formDT_myProfile } from "./data/my-profile/formDT_en-us";

export const enUS = {
  title: "Hello World!",
  home: {
    menu: menuDT_home,
    banner: bannerDT_home,
    explorer: explorerDT_home,
    services: servicesDT_home,
    technologies: technologiesDT_home,
    contact: contactDT_home,
    footer: footerDT_home,
  },
  signIn: {
    content: contentDT_signIn,
  },
  platform: {
    content: contentDT_platform,
  },
  users: {
    content: formDT_users,
  },
  contacts: {
    content: formDT_contacts,
  },
  myProfile: {
    form: formDT_myProfile,
  },
  common: {
    inputs: inputsDT_common,
    request: requestDT_common,
    delete: deleteDT_common,
  },
};