// LIBs
import styled from "styled-components";

export const ToolButton = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;

  .ToolButtonContainer {
    button {
      padding: 15px;
      border-radius: 999px;
      background-color: ${props => props.theme.theme.mode.bg};
      border: 1px solid ${props => props.theme.theme.green};

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: ${props => props.theme.sizes.ftBII}px;
        color: ${props => props.theme.theme.mode.font};
      }

      p {
        font-size: ${props => props.theme.sizes.ft}px;
        color: ${props => props.theme.theme.mode.font};
        font-weight: 200;
        overflow: hidden;
        transition: all ease 0.5s;
      }
    }
  }
`;
