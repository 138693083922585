export const menuDT_ptBR = {
  profile: {
    image: require("../../../../assets/images/my-profile.png"),
  },
  list: [
    {
      to: "banner",
      label: "inicio",
    },
    {
      to: "explorer",
      label: "explorar",
    },
    {
      to: "services",
      label: "serviços",
    },
    {
      to: "technologies",
      label: "tecnologias",
    },
    {
      to: "contact",
      label: "contato",
    },
  ],
};