// LIBs
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

// COMPONENTs
import { Modal } from "../../components/Modal";

// CONTAINERs
import { UsersList } from "../../containers/users/list";
import { UsersForm } from "../../containers/users/form";
import { CommonDelete } from "../../containers/common/delete";

// CONFIG
import { useToolkitSelector } from "../../core/config/store";

// CONSTANTs
import { useTranslate } from "../../core/constants/useTranslate";

// REDUCERs
import { getUser } from "../../core/reducers/user-reducer/selector";

// UTILs
import { getUsers, getUser as getUserApi, createUser, updateUser, deleteUser } from "../../core/utils/Api";

// TYPEs
import { IUserMinTypes, IUserTypes } from "../../types/usersTypes";

interface Object {
  [key: string]: any;
}

export function UsersView() {
  const userLogged = useToolkitSelector(getUser);

  const { t } = useTranslate();

  const [ user, setUser ] = useState<IUserTypes | null>(null);
  const [ userId, setUserId ] = useState<string>("");
  const [ users, setUsers ] = useState<IUserMinTypes[]>([]);

  useEffect(() => {
    fetchUsers();
  }, [])

  const fetchUsers = async () => {
    const req = await getUsers();

    if (req.data) {
      setUsers(req.data.users);
    } else {
      toast.error(t("common").request[req.error.message]);
    }
  }

  const fetchUser = async (id: string) => {
    const req = await getUserApi(id);

    if (req.data) {
      setUser(req.data.user);
    } else {
      toast.error(t("common").request[req.error.message]);
    }
  }

  const sendUser = async (name: string, email: string, password: string, hierarchy: string, status: string, id: string) => {
    const params: Object = { name, email, password, id_config_hierarchy: hierarchy, id_config_status: status };
    if (!user) {
      const req = await createUser({ ...params });

      if (req.data) {
        fetchUsers();
        toast.success(t("users").content?.user_created);
        return true;
      } else {
        toast.error(t("common").request[req.error.message]);
        return false;
      }
    } else {
      const newUser = {
        ...user,
        id_config_hierarchy: user.config_hierarchy.id,
        id_config_status: user.config_status.id,
      };
      const req = await updateUser(user.id, { ...params }, newUser);

      if (!req.error) {
        fetchUsers();
        setUser(null);
        toast.success(t("users").content?.user_updated);
        return true;
      } else {
        toast.error(t("common").request[req.error.message]);
        return false;
      }
    }
  }

  const delUser = async () => {
    const req = await deleteUser(userId);
    if (!req.error) {
      fetchUsers();
      if (userId === user?.id) setUser(null);
      setUserId("");
      toast.success(t("users").content?.user_deleted);
    } else {
      toast.error(t("common").request[req.error.message]);
    }
  }

  const access = userLogged ? JSON.parse(userLogged.config_hierarchy.access) : null;

  return (
    <main className="UsersView">
      { (access && access.users && access.users.includes("READ")) &&
        <UsersList 
          data={users}
          onEdit={fetchUser}
          onDelete={(id: string) => {
            setUserId(id);
          }}
        />
      }
      { (access && access.users && (access.users.includes("CREATE") || access.users.includes("UPDATE"))) &&
        <UsersForm 
          data={user}
          onCancel={() => {
            setUser(null);
          }}
          onSend={sendUser}
        />
      }
      { (access && access.users && access.users.includes("DELETE")) &&
        <Modal 
          show={!!userId}
          onHide={() => {
            setUserId("");
          }}
        >
          <CommonDelete 
            phrase={t("users").content?.modal_delete.phrase}
            onDelete={delUser}
            onCancel={() => {
              setUserId("");
            }}
          />
        </Modal>
      }
    </main>
  );
}
