// LIBs
import styled from "styled-components";

export const HeaderDash = styled.header`
  background-color: ${props => props.theme.theme.mode.bg};
  border-bottom: 2px solid ${props => props.theme.theme.green};

  .HeaderDash {
    padding: 15px 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &__left {
      .HeaderDashOpen {
        display: none;

        @media ${props => props.theme.screens.tb} {
          display: block;
        }

        button {
          padding: 5px;
  
          display: flex;
          justify-content: center;
          align-items: center;
  
          svg {
            font-size: ${props => props.theme.sizes.ftBII}px;
            color: ${props => props.theme.theme.mode.font};

            @media ${props => props.theme.screens.mbB} {
              font-size: ${props => props.theme.sizes.ftBIIm}vw;
            }
          }
        }
      }

    }

    &__right {
      display: flex;
      align-items: center;
      gap: 10px;

      .HeaderDashImage,
      .HeaderDashMore {
        img {
          width: 38px;
          height: 38px;
          object-fit: cover;
          object-position: center;
          border-radius: 5px;
        }

        span {
          padding: 10px;
          background-color: ${props => props.theme.theme.mode.bgV2};
          border-radius: 5px;
          cursor: pointer;

          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            font-size: ${props => props.theme.sizes.ftM}px;
            color: ${props => props.theme.theme.mode.font};

            @media ${props => props.theme.screens.mbB} {
              font-size: ${props => props.theme.sizes.ftMm}vw;
            }
          }
        }
      }

      .HeaderDashText {
        /* min-width: 100px; */

        p {
          font-size: ${props => props.theme.sizes.ftM}px;
          color: ${props => props.theme.theme.green};
          font-family: 'Handjet';

          @media ${props => props.theme.screens.mbB} {
            font-size: ${props => props.theme.sizes.ftMm}vw;
          }
        }
      }

      .HeaderDashMore {
        position: relative;

        span {
          background-color: transparent;
        }
      }
    }
  }
`;

export const ToolBoxContainer = styled.div<{ show: number }>`
  padding-top: 0;
  opacity: 0;
  pointer-events: none;
  transition: all ease 0.5s;

  position: absolute;
  right: 0;

  ${props => props.show === 1 ? `
    padding-top: 10px;
    opacity: 1;
    pointer-events: all;
  ` : ""}

  .ToolBoxContainer {
    padding: 10px;
    background-color: ${props => props.theme.theme.mode.bg};
    border-radius: 5px;
    border-bottom: 3px solid ${props => props.theme.theme.green};
    box-shadow: 0 0 8px ${props => props.theme.theme.mode.font+"6"};

    ul {
      display: flex;
      flex-direction: column;
      gap: 5px;

      li {
        a, button {
          width: 100%;
          padding: 5px 10px;
          border-radius: 5px;
          font-size: ${props => props.theme.sizes.ftS}px;
          font-weight: 600;
          color: ${props => props.theme.theme.mode.font};
          white-space: nowrap;
          transition: all ease 0.5s;

          display: flex;

          @media ${props => props.theme.screens.mbB} {
            font-size: ${props => props.theme.sizes.ftSm}vw;
          }

          &:hover {
            background-color: ${props => props.theme.theme.green+"66"};
          }
        }
      }
    }
  }
`;
