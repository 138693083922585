// STYLEs
import { CardServicesContainer } from "./styles";

export interface ICardServicesProps {
  Icon: any;
  title: string;
  textResum: string;
  active: boolean;
  onClick: any;
}

export function CardServices({ Icon, title, textResum, active, onClick }: ICardServicesProps) {
  return (
    <CardServicesContainer active={active ? 1 : 0} onClick={onClick}>
      <div className="CardServicesContainer">
        <div className="CardServicesContainer__icon">
          <span>
            <Icon />
          </span>
        </div>

        <div className="CardServicesContainer__title">
          <p
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </div>

        <div className="CardServicesContainer__resum">
          <p>{textResum}</p>
        </div>
      </div>
    </CardServicesContainer>
  );
}
