// LiBs
import { Link } from "react-scroll";

// COMPONENTs
import { Table, ITableColumnsTypes } from "../../../components/Table";

// CONFIG
import { useToolkitSelector } from "../../../core/config/store";

// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// REDUCERs
import { getUser } from "../../../core/reducers/user-reducer/selector";

// TYPEs
import { IContactsMinTypes } from "../../../types/contactsTypes";

// ICONs
import { FiEye, FiTrash } from "react-icons/fi";

// STYLEs
import { List } from "./styles";

interface IContactsListProps {
  data: IContactsMinTypes[];
  onView: any;
  onDelete: any;
}

export function ContactsList({ data, onView, onDelete }: IContactsListProps) {
  const user = useToolkitSelector(getUser);

  const { t } = useTranslate();

  const access = user ? JSON.parse(user.config_hierarchy.access) : null;

  const renderActions = (id: string) => {
    return (
      <div className="Links">
        <Link
          to="view-contacts"
          duration={400}
          smooth
          onClick={() => {
            onView(id);
          }}
        >
          <FiEye />
        </Link>
        { (access && access.contacts && access.contacts.includes("DELETE")) &&
          <a 
            href="#"
            onClick={(event: any) => {
              event.preventDefault();
              onDelete(id);
            }}
          >
            <FiTrash />
          </a>
        }
      </div>
    );
  }

  const columns: ITableColumnsTypes[] = [
    {
      label: t("contacts").content?.table.subject,
      name: "subject",
    },
    {
      label: t("contacts").content?.table.email,
      name: "email",
    },
    {
      label: t("contacts").content?.table.actions,
      name: "id",
      renderItem: renderActions,
    },
  ];

  return (
    <List>
      <div className="List">
        <div className="List__header">
          <h1>{t("contacts").content?.title}</h1>
        </div>

        <div className="List__table">
          <Table 
            columns={columns}
            rows={data}
          />
        </div>
      </div>
    </List>
  );
}
