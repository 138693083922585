// LIBs
import styled from "styled-components"

export const Footer = styled.footer`
  background-color: ${props => props.theme.theme.mode.bg};

  .Footer {
    max-width: 1280px;
    width: 100%;
    padding: 80px 20px;
    padding-bottom: 120px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      margin-bottom: 20px;

      h4 {
        font-size: ${props => props.theme.sizes.ftXI}px;
        font-weight: 500;
        color: ${props => props.theme.theme.mode.font};

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftXIm}vw;
        }
      }
    }

    &__menu {
      margin-bottom: 30px;
    }

    &__social {
      margin-bottom: 40px;

      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;

      a {
        padding: 20px;
        border-radius: 50%;
        border: 1px solid ${props => props.theme.theme.green};
        transition: all ease 0.5s;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          font-size: ${props => props.theme.sizes.ftBII}px;
          color: ${props => props.theme.theme.green};
          transition: all ease 0.5s;

          @media ${props => props.theme.screens.mbB} {
            font-size: ${props => props.theme.sizes.ftBIIm}vw;
          }
        }

        &:hover {
          background-color: ${props => props.theme.theme.green};

          svg {
            color: ${props => props.theme.theme.mode.bg};
          }
        }
      }
    }

    &__text {
      max-width: 568px;
      width: 100%;
      margin: 0 auto;
      text-align: center;

      p {
        font-size: ${props => props.theme.sizes.ftR}px;
        color: ${props => props.theme.theme.mode.font};
        line-height: ${props => props.theme.sizes.ftR + 10}px;
        text-transform: uppercase;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftRm}vw;
          line-height: ${props => props.theme.sizes.ftRm + 2}vw;
        }
      }
    }
  }

  .FooterCopywriter {
    padding: 20px;
    border-top: 1px solid ${props => props.theme.theme.green};
    text-align: center;

    p {
      font-size: ${props => props.theme.sizes.ftS}px;
      color: ${props => props.theme.theme.mode.font};
      line-height: ${props => props.theme.sizes.ftS + 10}px;
      text-transform: uppercase;

      @media ${props => props.theme.screens.mbB} {
        font-size: ${props => props.theme.sizes.ftSm}vw;
        line-height: ${props => props.theme.sizes.ftSm + 2}vw;
      }
    }
  }
`;
