// LIBs
import styled from "styled-components";

export const ModalContainer = styled.div<{ show: number }>`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.theme.mode.bg+"6"};
  backdrop-filter: blur(10px);
  padding: 20px;
  opacity: 0;
  pointer-events: none;
  transition: all ease 0.5s;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;

  ${props => props.show === 1 && `
    opacity: 1;
    pointer-events: all;
  `}

  .ModalContainer {
    margin-top: -20px;
    transition: all ease 0.5s;

    ${props => props.show && `
      margin-top: 0;
    `}
  }
`;
