// LIBs
import { useLayoutEffect, ReactNode, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

// CONFIG
import { useToolkitSelector, useToolkitDispatch } from "../../core/config/store";

// CONSTANTs
import { getToken } from "../../core/reducers/user-reducer/selector"; 

// REDUCERs
import { setUser } from "../../core/reducers/user-reducer";

// UTILs
import { getMe } from "../../core/utils/Api";

// LAYOUT
import { ContentDashLightLayout } from "../../layout/content-dash-light";
import { GridDashLightLayout } from "../../layout/grid-dash-light";
import { SidebarDashLightLayout } from "../../layout/sidebar-dash-light";

interface IDashboardLightTemplateProps {
  children: ReactNode;
}

const to = "/dashboard/sign-in";

export function DashboardLightTemplate({ children }: IDashboardLightTemplateProps) {
  const token = useToolkitSelector(getToken);

  const navigate = useNavigate();
  const dispatch = useToolkitDispatch();

  const [ shownSidebar, setShownSidebar ] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (token) fetchUser();
  }, [])

  const fetchUser = async () => {
    const req = await getMe();
    if (req.data) {
      dispatch(setUser(req.data.user));
    } else {
      navigate(to);
    }
  }

  if (token) {
    return (
      <>
        <GridDashLightLayout 
          onShow={() => {
            setShownSidebar(true);
          }}
        >
          <SidebarDashLightLayout 
            show={shownSidebar} 
            onHide={() => {
              setShownSidebar(false);
            }}
          />
          <ContentDashLightLayout>
            {children}
          </ContentDashLightLayout>
        </GridDashLightLayout>
      </>
    );
  } else {
    return <Navigate to="/dashboard/sign-in" />;
  }
}