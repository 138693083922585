// LIBs
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";

// CONFIG
import { Router } from './core/config/router';
import { store } from './core/config/store';

// REDUCERs
import { setScrollTop, setScreen } from './core/reducers/app-reducer';

// THEME
import { Theme } from './core/theme/Theme';

// STYLEs
import "./core/theme/index.css";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <Theme>
      <Router />
    </Theme>
  </Provider>
);

window.onscroll = function() {
  store.dispatch(setScrollTop(window.scrollY));
}

window.onload = function() {
  getResize();
}

window.onresize = function() {
  getResize();
}

function getResize() {
  store.dispatch(setScreen({
    width: window.screen.width,
    height: window.screen.height,
  }));
}
