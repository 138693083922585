// COMPONENTs
import { Button } from "../../../components/Button";

// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// ICONs
import { FiTrash } from "react-icons/fi";

// STYLEs
import { DeleteContainer } from "./styles";

interface ICommonDeleteProps {
  phrase: string;
  onDelete: any;
  onCancel: any;
}

export function CommonDelete({ phrase, onDelete, onCancel }: ICommonDeleteProps) {
  const { t } = useTranslate();

  return (
    <DeleteContainer>
      <div className="DeleteContainer">
        <div className="DeleteContainer__icon">
          <span>
            <FiTrash />
          </span>
        </div>

        <div className="DeleteContainer__text">
          <p>{phrase}</p>
        </div>

        <div className="DeleteContainer__buttons">
          <Button 
            title={t("common").delete?.btn_delete}
            color="danger"
            onClick={() => {
              onDelete();
            }}
          />
          <Button 
            title={t("common").delete?.btn_cancel}
            onClick={() => {
              onCancel();
            }}
          />
        </div>
      </div>
    </DeleteContainer>
  );
}
