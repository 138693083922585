// LIBs
import { useState, useEffect, useRef } from "react";

// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// ICONs
import { FiSettings } from "react-icons/fi";

// STYLEs
import { ToolButton } from "./styles";

interface IHomeToolButtonProps {
  onClick: any;
}

export function HomeToolButton({ onClick }: IHomeToolButtonProps) {
  const { t } = useTranslate();

  const labelRef = useRef<HTMLParagraphElement>(null);

  const [ widthLabel, setWidthLabel ] = useState(0);

  useEffect(() => {
    if (labelRef.current) {
      setWidthLabel(labelRef.current.scrollWidth);

      setTimeout(() => {
        setWidthLabel(0);
      }, 2500)
    }
  }, [])

  return (
    <ToolButton 
      onMouseEnter={() => {
        if (labelRef.current) {
          setWidthLabel(labelRef.current.scrollWidth);
        }
      }}
      onMouseLeave={() => {
        setWidthLabel(0);
      }}
    >
      <div className="ToolButtonContainer">
        <button onClick={onClick}>
          <p 
            style={{
              width: widthLabel,
              marginRight: widthLabel ? 10 : 0,
              opacity: widthLabel ? 1 : 0,
            }}
            ref={labelRef}
          >
            {t("common").inputs?.btn_settings}
          </p>
          <FiSettings />
        </button>
      </div>
    </ToolButton>
  );
}
