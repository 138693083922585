// LIBs
import styled from "styled-components";

export const ContentDash = styled.div`
  width: 100%;
  /* width: calc(100vw - 400px); */
  flex: 1;
  padding: 20px;
  background: linear-gradient(110deg, ${props => props.theme.theme.mode.bgGreen} 0%, ${props => props.theme.theme.mode.bg} 100.48%);

  /* @media ${props => props.theme.screens.tb} {
    width: 100vw;
  } */

  .ContentDash {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: ${props => props.theme.theme.mode.bg};
  }
`;
