// STYLEs
import { SelectContainer } from "./styles";

// ICONs
import { FiChevronDown } from "react-icons/fi";

export interface ISelectListTypes {
  name: string;
  id: number | string;
}

export interface ISelectProps {
  label: string;
  value: string;
  data: ISelectListTypes[];
  onChange: any;
}

export function Select({ label, value, data, onChange }: ISelectProps) {
  return (
    <SelectContainer>
      <p>{label}</p>
      <div className="SelectContainer">
        <select 
          value={value}
          onChange={onChange}
        >
          { data.length > 0 &&
            data.map((el, index) => (
              <option 
                value={el.id}
                key={index}
              >
                {el.name}
              </option>
            ))
          }
        </select>
        <span>
          <FiChevronDown />
        </span>
      </div>
    </SelectContainer>
  );
}
