// LIBs
import styled from "styled-components";

export const Banner = styled.section`
  height: auto;
  min-height: 100vh;
  padding: 139px 0;
  background: linear-gradient(110deg, ${props => props.theme.theme.mode.bgGreen} 0%, ${props => props.theme.theme.mode.bg} 100.48%);
  overflow: hidden;

  display: flex;
  flex-direction: column;

  position: relative;

  @media ${props => props.theme.screens.tb} {
    padding-bottom: 0;
  }

  .BannerContainer {
    max-width: 1280px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;

    flex: 1;
    display: flex;
    align-items: center;

    @media ${props => props.theme.screens.tb} {
      flex-direction: column;
    }

    @media ${props => props.theme.screens.tb} {
      max-width: 450px;
    }

    &__details {
      @media ${props => props.theme.screens.tb} {
        margin-bottom: 40px;
      }

      .BannerDetails {
        position: relative;
        z-index: 1;

        &__subtitle {
          margin-bottom: 20px;

          h2 {
            font-size: ${props => props.theme.sizes.ftBII}px;
            color: ${props => props.theme.theme.green};
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 2.4px;

            @media ${props => props.theme.screens.mbB} {
              font-size: ${props => props.theme.sizes.ftBIIm}vw;
              line-height: ${props => props.theme.sizes.ftBIIm + 0.5}vw;
            }
          }
        }

        &__title {
          max-width: 400px;

          h1, span {
            font-size: ${props => props.theme.sizes.ftB * 2}px;
            color: ${props => props.theme.theme.mode.font};
            font-weight: 500;
            text-transform: uppercase;

            @media ${props => props.theme.screens.mbB} {
              font-size: ${props => props.theme.sizes.ftBm * 2}vw;
            }

            span {
              color: ${props => props.theme.theme.green};
            }
          }

          &::after {
            content: '';
            width: 25%;
            height: 5px;
            background-color: ${props => props.theme.theme.green};
            margin: 15px 0 40px 0;

            display: block;

            @media ${props => props.theme.screens.mbB} {
              height: 1.1vw;
            }
          }
        }

        &__text {
          max-width: 466px;

          p {
            font-size: ${props => props.theme.sizes.ftR};
            line-height: ${props => props.theme.sizes.ftR + 10}px;
            text-transform: uppercase;
            font-weight: 500;
            color: ${props => props.theme.theme.mode.fontV2};
            letter-spacing: 1.28px;

            @media ${props => props.theme.screens.mbB} {
              font-size: ${props => props.theme.sizes.ftRm}vw;
              line-height: ${props => props.theme.sizes.ftRm + 2}vw;
            }
          }
        }
      }
    }

    &__image {
      img {
        width: 50%;

        position: absolute;
        right: 10%;
        bottom: 0;

        @media ${props => props.theme.screens.dsS} {
          width: 75%;

          right: -5%;
        }

        @media ${props => props.theme.screens.tb} {
          width: 100%;

          position: unset;
        }
      }
    }
  }

  .BannerBG {
    width: 100%;
    height: 100px;
    background: linear-gradient(0deg, ${props => props.theme.theme.mode.bg} 0%, transparent 100%);

    position: absolute;
    bottom: 0;
    left: 0;
  }
`;
