// LIBs
import { ReactNode, useMemo } from "react";
import { ThemeProvider } from "styled-components";

// CONFIG
import { useToolkitSelector } from "../config/store";

// REDUCERs
import { getMode } from "../reducers/app-reducer/selector";

// THEME
import { colors, theme } from "./colors";
import { screens } from "./screens";
import { sizes } from "./sizes";

interface IThemeProps {
  children: ReactNode;
}

export function Theme({ children }: IThemeProps) {
  const mode = useToolkitSelector(state => getMode(state));

  const themeColor: any = useMemo(() => {
    return { mode: theme[mode], ...colors };
  }, [mode]);

  return (
    <ThemeProvider 
      theme={{
        theme: { ...themeColor },
        screens: { ...screens },
        sizes: { ...sizes },
      }}
    >
      {children}
    </ThemeProvider>
  );
}
