export const menuDT_enUS = {
  profile: {
    image: require("../../../../assets/images/my-profile.png"),
  },
  list: [
    {
      to: "banner",
      label: "home",
    },
    {
      to: "explorer",
      label: "explorer",
    },
    {
      to: "services",
      label: "services",
    },
    {
      to: "technologies",
      label: "technologies",
    },
    {
      to: "contact",
      label: "contact",
    },
  ],
};