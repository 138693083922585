// CONFIG
import { RootState } from "../../config/store";

const app = (state: RootState) => state.app;
export const getLanguage = (state: RootState) => app(state).language;
export const getMode = (state: RootState) => app(state).mode;
export const getScrollTop = (state: RootState) => app(state).scrollTop;
export const getActiveLink = (state: RootState) => app(state).activeLink;
export const getScreen = (state: RootState) => app(state).screen;
export const getAll = (state: RootState) => app(state);
