export const explorerDT_enUS = {
  title: "Explore my projects",
  text: "See all the projects produced with us, from personal projects to those of other clients, see the wonders of web and mobile development, and be the next to build and propagate your business online with your own website or application.",
  categories_projects_title: "Project type:",
  categories_projects: [
    "All",
    "One Page",
    "Landing Page",
    "System",
    "Institutional",
    "Ecommerce",
    "App",
  ],
  categories_technologies_title: "Technologies Used:",
  categories_technologies: [
    "All",
    "Vanila",
    "PHP",
    "Wordpress",
    "ReactJS",
    "React Native",
    "NodeJS",
    'Laravel',
  ],
  projects: [
    {
      new: true,
      model: true,
      name: "Institutional Engenharia",
      about: "The institutional engineering project focused on presenting a company focused on civil engineering. It was developed for a client, this is a fullstack project where we have the frontend and backend in the same project, I used the MVC architecture in PHP to build this application.",
      cover: require("../../../../assets/images/projects/engenharia/engenharia_cover.png"),
      images: [
        require("../../../../assets/images/projects/engenharia/home.jpeg"),
        require("../../../../assets/images/projects/engenharia/about.jpeg"),
        require("../../../../assets/images/projects/engenharia/projects.jpeg"),
        require("../../../../assets/images/projects/engenharia/portfolio.jpeg"),
        require("../../../../assets/images/projects/engenharia/dashboard.jpeg"),
      ],
      tags: ["All", "Institutional"],
      technologies: ["All", "PHP"],
      link_github: "https://github.com/LevyAlves1532/engenharia",
      reference: null,
      link_download: null,
      link_preview: null,
      link_figma: null,
    },
    {
      new: false,
      model: true,
      name: "Ticket Events",
      about: "Developed a simple event manager that allows full control over buyers, including full CRUD, migrations and seeder functionality, with robust validations implemented on both the frontend and backend.",
      cover: require("../../../../assets/images/projects/ticket_events/ticket_events_cover.png"),
      images: [
        require("../../../../assets/images/projects/ticket_events/home.jpeg"),
        require("../../../../assets/images/projects/ticket_events/events.jpeg"),
        require("../../../../assets/images/projects/ticket_events/edit_event.jpeg"),
        require("../../../../assets/images/projects/ticket_events/add_buyer.jpeg"),
        require("../../../../assets/images/projects/ticket_events/add_buyer_in_event.jpeg"),
      ],
      tags: ["All", "System"],
      technologies: ["All", "Laravel", "PHP"],
      link_github: "https://github.com/LevyAlves1532/ticket-events/tree/master",
      reference: null,
      link_download: null,
      link_preview: null,
      link_figma: null,
    },
    {
      new: false,
      model: true,
      name: "SGE",
      about: "This project was built as part of a learning process, I created an inventory manager based on a contact manager I took on a course, and brought new features, such as image uploading, filtering by categories and much more.",
      cover: require("../../../../assets/images/projects/sge/sge_cover.png"),
      images: [
        require("../../../../assets/images/projects/sge/sign-in.jpeg"),
        require("../../../../assets/images/projects/sge/home.jpeg"),
        require("../../../../assets/images/projects/sge/categories.jpeg"),
        require("../../../../assets/images/projects/sge/categories-add.jpeg"),
        require("../../../../assets/images/projects/sge/products-edit.jpeg"),
      ],
      tags: ["All", "System"],
      technologies: ["All", "NodeJS"],
      link_github: "https://github.com/LevyAlves1532/sistema-de-estoque",
      reference: null,
      link_download: null,
      link_preview: null,
      link_figma: null,
    },
    {
      new: false,
      model: true,
      name: "Barber Web Template",
      about: "The Barber Web Template is a project focused on presenting a barbershop. Developed as part of personal learning, focusing mainly on the interface and user experience on a professional One Page.",
      cover: require("../../../../assets/images/projects/barber_web/barber_web_cover.png"),
      images: [
        require("../../../../assets/images/projects/barber_web/banner.jpeg"),
        require("../../../../assets/images/projects/barber_web/about.jpeg"),
        require("../../../../assets/images/projects/barber_web/services.jpeg"),
        require("../../../../assets/images/projects/barber_web/portfolio.jpeg"),
        require("../../../../assets/images/projects/barber_web/contact.jpeg"),
      ],
      tags: ["All", "One Page"],
      technologies: ["All", "ReactJS"],
      link_github: "https://github.com/LevyAlves1532/barber-web-template",
      reference: null,
      link_download: null,
      link_preview: "https://jsninjajourney.com.br/sites/barber-web-template/",
      link_figma: "https://www.figma.com/file/bQ8oI26ml0jaLbHkUkEhCA/Barber-Web-Design?type=design&node-id=0%3A1&mode=design&t=0necF8FOt6yOu8aw-1",
    },
    {
      new: false,
      model: true,
      name: "Catalog Pizzas",
      about: "Catalog Pizzas is an interactive platform that allows pizza lovers to customize their delights and forward their orders directly to the pizzeria's WhatsApp.",
      cover: require("../../../../assets/images/projects/catalog_pizzas/catalog_pizza_cover.png"),
      images: [
        require("../../../../assets/images/projects/catalog_pizzas/home.jpeg"),
        require("../../../../assets/images/projects/catalog_pizzas/ingredients.jpeg"),
        require("../../../../assets/images/projects/catalog_pizzas/drinks.jpeg"),
        require("../../../../assets/images/projects/catalog_pizzas/cart_one.jpeg"),
        require("../../../../assets/images/projects/catalog_pizzas/cart_second.jpeg"),
      ],
      tags: ["All", "System"],
      technologies: ["All", "ReactJS"],
      link_github: "https://github.com/LevyAlves1532/catalog-pizza",
      reference: null,
      link_download: null,
      link_preview: null,
    },
    {
      new: false,
      model: true,
      name: "Pizza Shop",
      about: "Pizza Shop is an application design project focused on purchasing pizzas, prioritizing an intuitive user experience for pizzerias. Part of personal learning.",
      cover: require("../../../../assets/images/projects/pizza_shop/pizza_shop_cover.png"),
      images: [
        require("../../../../assets/images/projects/pizza_shop/header_home.jpeg"),
        require("../../../../assets/images/projects/pizza_shop/info_pizza.jpeg"),
        require("../../../../assets/images/projects/pizza_shop/buy_cart.jpeg"),
        require("../../../../assets/images/projects/pizza_shop/header_favorites.jpeg"),
        require("../../../../assets/images/projects/pizza_shop/header_historic.jpeg"),
      ],
      tags: ["All", "App"],
      technologies: ["All", "React Native"],
      link_github: "https://github.com/LevyAlves1532/pizza_shop_rn",
      reference: null,
      link_download: null,
      link_preview: null,
    },
  ],
  card_subtitle: "New Project",
  found_projects: "There is no project with these specifications!",
  button_contact: "Our services",
};