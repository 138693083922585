// LIBs
import styled from "styled-components";

export const ServicesContainer = styled.section`
  padding: 120px 0;
  background: linear-gradient(270deg, ${props => props.theme.theme.mode.bgGreen} 0%, ${props => props.theme.theme.mode.bg} 100%);
  overflow: hidden;

  .ServicesContainer {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    display: flex;
    gap: 60px;
    align-items: center;

    @media ${props => props.theme.screens.dsS} {
      flex-direction: column;
    }

    @media ${props => props.theme.screens.mbB} {
      padding: 0;

      gap: 105px;
    }

    &__list {
      flex: 1;

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      
      @media ${props => props.theme.screens.dsS} {
        max-width: 580px;
        width: 100%;
        margin: 0 auto;
        
        order: 1;
      }

      @media ${props => props.theme.screens.mbB} {
        display: unset;
      }

      .slick-list {
        overflow: unset;
      }

      .slick-track {
        padding: 0 20px;

        display: flex;

        .slick-slide {
          padding: 0 10px;

          height: auto;
          display: flex;

          &.slick-current {
            >div {
              >div {
                transform: translateY(-25%);
              }
            }
          }

          >div {
            width: 100%;
            height: 100%;

            >div {
              transition: all ease 0.5s;
            }
          }
        }
      }

      .slick-dots {
        li {
          button {
            &::before {
              font-size: ${props => props.theme.sizes.ftS}px;
              color: ${props => props.theme.theme.green};
            }
          }
        }
      }

      >div {
        transition: all ease 0.5s;

        &:nth-child(2n) {
          margin-top: 30px;

          @media ${props => props.theme.screens.mbB} {
            margin-top: initial;
          }
        }

        &:hover {
          transform: scale(0.8);

          @media ${props => props.theme.screens.mbB} {
            transform: initial;
          }
        }
      }
    }

    &__details {
      flex: 1;

      @media ${props => props.theme.screens.dsS} {
        max-width: 580px;
        margin: 0 auto;
      }

      @media ${props => props.theme.screens.tb} {
        max-width: 450px;
      }

      @media ${props => props.theme.screens.mbB} {
        padding: 0 20px;
      }

      .ServicesDetails {
        &__title {
          margin-bottom: 20px;

          @media ${props => props.theme.screens.tb} {
            text-align: center;
          }

          h3, span {
            font-size: ${props => props.theme.sizes.ftBI*2}px;
            font-weight: 500;
            color: ${props => props.theme.theme.mode.font};

            @media ${props => props.theme.screens.mbB} {
              font-size: ${props => props.theme.sizes.ftBIm*2}vw;
            }
          }

          span {
            color: ${props => props.theme.theme.green};
          }
        }

        &__text {
          margin-bottom: 40px;

          @media ${props => props.theme.screens.tb} {
            text-align: center;
          }

          p {
            font-size: ${props => props.theme.sizes.ftM}px;
            color: ${props => props.theme.theme.mode.fontV2};
            line-height: ${props => props.theme.sizes.ftM+10}px;
            font-weight: 500;

            @media ${props => props.theme.screens.mbB} {
              font-size: ${props => props.theme.sizes.ftMm}vw;
              line-height: ${props => props.theme.sizes.ftMm+2}vw;
            }
          }
        }

        &__more {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media ${props => props.theme.screens.tb} {
            flex-direction: column;
            gap: 40px;
            align-items: center;
          }

          .ServicesIcons {
            &__title {
              margin-bottom: 10px;

              @media ${props => props.theme.screens.tb} {
                text-align: center;
              }

              p {
                font-size: ${props => props.theme.sizes.ftR}px;
                color: ${props => props.theme.theme.green};
                line-height: ${props => props.theme.sizes.ftR+10}px;
                font-weight: 500;
                text-transform: uppercase;

                @media ${props => props.theme.screens.mbB} {
                  font-size: ${props => props.theme.sizes.ftRm}vw;
                  line-height: ${props => props.theme.sizes.ftRm+2}vw;
                }
              }
            }

            &__list {
              display: flex;
              gap: 10px;

              @media ${props => props.theme.screens.tb} {
                justify-content: center;
              }

              span {
                font-size: ${props => props.theme.sizes.ftBII}px;
                color: ${props => props.theme.theme.green};
                transition: all ease 0.5s;

                @media ${props => props.theme.screens.mbB} {
                  font-size: ${props => props.theme.sizes.ftBIIm}vw;
                }

                &:hover {
                  transform: scale(1.1);
                }
              }
            }
          }
        }
      }
    }
  }
`;
