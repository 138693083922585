// LIBs
import { useState, useEffect, ChangeEvent } from "react";

// COMPONENTs
import { CardTechnologies } from "../../../components/CardTechnologies";
import { Button } from "../../../components/Button";
import { Select } from "../../../components/Select";

// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// STYLEs
import { TechnologiesContainer } from "./styles";

interface IHomeTechnologiesProps {
  category: number;
  onChangeCategory: any;
}

export function HomeTechnologies({ category, onChangeCategory }: IHomeTechnologiesProps) {
  const { t, language } = useTranslate();

  const [ shownAll, setShownAll ] = useState<boolean>(false);
  const [ technologies, setTechnologies ] = useState<any[]>(t("home").technologies?.list);

  useEffect(() => {
    setTechnologies(t("home").technologies?.list.filter((technologie: any) => {
      if (technologie.categories.includes(t("home").technologies?.categories[category])) {
        return technologie;
      }
    }))
  }, [category, language])

  const filterTechnologies: any[] = []; 

  technologies.forEach((technologie: any) =>  {
    if (!shownAll && filterTechnologies.length < 6) {
      filterTechnologies.push(technologie);
    } else if (shownAll) {
      filterTechnologies.push(technologie);
    }
  })

  return (
    <TechnologiesContainer id="technologies">
      <div className="TechnologiesContainer">
        <div className="TechnologiesContainer__title">
          <h3>{t("home").technologies?.title}</h3>
        </div>
        <div className="TechnologiesContainer__text">
          <p>{t("home").technologies?.text}</p>
        </div>
        <div className="TechnologiesContainer__filter">
          <Select 
            label={t("home").technologies?.filter_text}
            data={t("home").technologies?.categories.map((category: string, index: number) => ({
              name: category,
              id: index,
            }))}
            value={`${category}`}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              onChangeCategory(+event.target.value);
            }}
          />
        </div>
        <div className="TechnologiesContainer__list">
          {
            filterTechnologies.map((technologie: any, index: number) => (
              <CardTechnologies 
                Icon={technologie.icon}
                timeUsed={technologie.time_used}
                name={technologie.name}
                description={technologie.description}
                key={index}
              />
            ))
          }
        </div>
        <div className="TechnologiesContainer__buttons">
          { technologies.length > 6 &&
            <Button 
              title={
                !shownAll 
                  ? t("home").technologies?.btn_text_more 
                  : t("home").technologies?.btn_text_less
              }
              onClick={() => setShownAll(!shownAll)}
            />
          }
          <Button 
            type="link-scroll"
            to="contact"
            title={t("home").technologies?.btn_text_budget}
          />
        </div>
      </div>
    </TechnologiesContainer>
  );
}
