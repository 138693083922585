// LIBs
import styled from "styled-components";

export const SidebarDashLight = styled.aside<{ show: number }>`
  width: 375px;
  border-right: 2px solid ${props => props.theme.theme.green};
  padding-bottom: 40px;

  @media ${props => props.theme.screens.tb} {
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.theme.mode.bg};
    transform: translateX(-100%);
    transition: all ease 0.8s;
    z-index: 10;

    position: fixed;
    top: 0;
    left: 0;

    ${props => props.show === 1 ? `
      transform: translateX(0);
    ` : ""}
  }

  .SidebarDashLight {
    &__header {
      padding-top: 20px;
      text-align: center;
      margin-bottom: 40px;

      display: none;
      justify-content: center;
      align-items: center;

      position: relative;

      @media ${props => props.theme.screens.tb} {
        display: flex;
      }

      h2, span {
        font-size: ${props => props.theme.sizes.ftBIII}px;
        color: ${props => props.theme.theme.mode.font};
        font-weight: 500;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftBIIIm * 2}vw;
        }

        span {
          font-size: ${props => props.theme.sizes.ftBII}px;
          color: ${props => props.theme.theme.green};
          font-family: 'Handjet';
        }
      }

      button {
        padding: 10px;

        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        right: 20px;

        svg {
          font-size: ${props => props.theme.sizes.ftBII}px;
          color: ${props => props.theme.theme.mode.font};

          @media ${props => props.theme.screens.mbB} {
            font-size: ${props => props.theme.sizes.ftBIIm}vw;
          }
        }
      }
    }
    
    ul {
      li {
        transition: all ease 0.5s;

        display: flex;

        &::before {
          content: '';
          width: 0;
          background-color: ${props => props.theme.theme.green};
          transition: all ease 0.3s;

          display: block;
        }

        &.active-link {
          background-color: ${props => props.theme.theme.green+"44"};

          &::before {
            width: 5px;
          }
        }

        &:hover {
          background-color: ${props => props.theme.theme.green+"44"};
        }

        a, button {
          width: 100%;
          padding: 15px 20px;
          font-size: ${props => props.theme.sizes.ftM}px;
          color: ${props => props.theme.theme.mode.font};

          display: flex;

          @media ${props => props.theme.screens.mbB} {
            font-size: ${props => props.theme.sizes.ftMm}vw;
          }
        }
      }
    }
  }
`;
