// LIBs
import styled from "styled-components";

export const TableContainer = styled.div`
  .TableContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__list {
      width: 100%;
      overflow-x: auto;

      table {
        width: 100%;
        min-width: 768px;
        border-spacing: 0;
        border-radius: 20px;
        overflow: hidden;

        thead {
          background: linear-gradient(110deg, ${props => props.theme.theme.mode.bgGreen} 0%, ${props => props.theme.theme.mode.bg} 100.48%);

          tr {
            th {
              padding: 20px;
              font-size: ${props => props.theme.sizes.ft}px;
              color: ${props => props.theme.theme.mode.font};
              text-align: left;
              cursor: pointer;

              @media ${props => props.theme.screens.mbB} {
                font-size: ${props => props.theme.sizes.ftm}vw;
              }
            }
          }
        }

        tbody {
          tr {
            &:nth-child(2n) {
              background-color: ${props => props.theme.theme.mode.bgGreen+"66"};
            }

            td {
              padding: 20px;
              font-size: ${props => props.theme.sizes.ftR}px;
              color: ${props => props.theme.theme.mode.font};

              @media ${props => props.theme.screens.mbB} {
                font-size: ${props => props.theme.sizes.ftRm}vw;
              }
            }
          }
        }
      }
    }
  }
`;
