// COMPONENTs
import { Select } from "../../../components/Select";
import { Swicth } from "../../../components/Switch";

// CONFIG
import { useToolkitSelector, useToolkitDispatch } from "../../../core/config/store";

// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// REDUCERs
import { setMode } from "../../../core/reducers/app-reducer";
import { getMode } from "../../../core/reducers/app-reducer/selector";

// STYLEs
import { Form } from "./styles";

export function SettingsForm() {
  const dispatch = useToolkitDispatch();
  
  const mode = useToolkitSelector(getMode);
  const { t, language, changeLanguage } = useTranslate();

  return (
    <Form>
      <div className="SettingsForm">
        <Select 
          label={t("common").inputs?.inputs.language.label}
          value={language}
          data={t("common").inputs?.inputs.language.data}
          onChange={(event: any) => {
            changeLanguage(event.target.value);
          }}
        />
        <div>
          <p>{t("common").inputs?.inputs.mode.label}</p>
          <Swicth 
            value={mode === "light" ? false : true}
            onChecked={() => {
              dispatch(setMode(mode === "light" ? "dark" : "light"));
            }}
          />
        </div>
      </div>
    </Form>
  );
}
