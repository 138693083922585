export const contentDT_ptBR = {
  title: "Lista de Contatos",
  table: {
    subject: "Assunto:",
    email: "E-mail:",
    actions: "Ações:",
  },
  subtitle: "Ver Contato - contactId",
  phrase: "Você deseja deletar este contato?",
  contact_deleted: "Contato deletado com sucesso!",
};
