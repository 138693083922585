export const contactDT_enUS = {
  title: "Come with us!",
  description: "Turn your Vision into Reality - We Develop Websites and Applications that Impress. Join the Digital Revolution!",
  label_name: "Name:",
  placeholder_name: "Type your name...",
  label_email: "Email:",
  placeholder_email: "Type your e-mail...",
  label_phone: "Phone:",
  placeholder_phone: "Enter your phone number...",
  label_subject: "Subject:",
  placeholder_subject: "Enter the subject...",
  label_text: "About:",
  placeholder_text: "Type about your project...",
  accept_conditions: "Do you accept the",
  accept_conditions_link: "terms of use.",
  button_contact: "Request a Quote",
  success_title: "Form sent Successfully!",
  success_message: "Keep an eye on your WhatsApp or Email inbox, we'll be in touch soon!",
  inputs_invalid: "Complete all the fields correctly!",
  form_block: "Form blocked!",
  email_invalid: "Invalid email!",
  phone_invalid: "Invalid phone!",
  text_invalid: "The field needs to have more than how many qtdCarac characters!",
  modal_title: "Message sent!",
  modal_text: "Your message has been sent successfully, we will contact you shortly, keep an eye on your email inbox!",
  form_request: {
    "Falta paramêtros na requisição!": "The request is missing parameters!",
  },
};