export const inputsDT_enUS = {
  inputs: {
    photo: {
      label: "Profile Image:"
    },
    name: {
      label: "Full name:",
      placeholder: "Enter the user's full name...",
    },
    email: {
      label: "Email:",
      placeholder: "Enter the user's email...",
    },
    password: {
      label: "Password:",
      placeholder: "Enter the user password...",
    },
    hierarchy: {
      label: "Hierarchy:",
    },
    status: {
      label: "Status:",
    },
    language: {
      label: "Choose your preferred language:",
      data: [
        {
          id: "pt-BR",
          name: "Portuguese",
        },
        {
          id: "en-US",
          name: "English",
        },
      ],
    },
    mode: {
      label: "Enable dark mode?",
    },
  },
  settings_title: "Display Settings",
  btn_settings: "Settings",
  inputs_invalid: "Complete all fields correctly!",
  email_invalid: "Invalid email!",
  phone_invalid: "Invalid phone!",
  text_invalid: "The field must be longer than qtdCarac characters!",
  password_invalid: {
    carac: "Must be more than 7 characters!",
    uppercase: "It must have at least one capital letter!",
    number: "It must have at least one number!",
    special: "It must have at least one special character!",
  },
};