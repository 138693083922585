// LIBs
import styled from "styled-components";

export const CardTechnologiesContainer = styled.div`
  background-color: ${props => props.theme.theme.mode.bgGreen+"99"};
  border-radius: 10px;
  overflow: hidden;
  border-bottom: 3px solid ${props => props.theme.theme.green};

  .CardTechnologiesContainer {
    padding: 20px;

    &__info {
      margin-bottom: 20px;

      .CardTechnologiesInfo {
        display: flex;
        gap: 10px;
        align-items: center;

        &__icon {
          span {
            font-size: ${props => props.theme.sizes.ftBII + 10}px;
            color: ${props => props.theme.theme.mode.font};

            @media ${props => props.theme.screens.mbB} {
              font-size: ${props => props.theme.sizes.ftBIIm + 3}vw;
            }
          }
        }

        &__details {
          p {
            font-size: ${props => props.theme.sizes.ft}px;
            font-weight: 600;
            color: ${props => props.theme.theme.mode.font};
            letter-spacing: 1px;

            @media ${props => props.theme.screens.mbB} {
              font-size: ${props => props.theme.sizes.ftm}vw;
            }
          }

          span {
            font-size: ${props => props.theme.sizes.ftS}px;
            font-weight: 200;
            color: ${props => props.theme.theme.mode.fontV2};

            @media ${props => props.theme.screens.mbB} {
              font-size: ${props => props.theme.sizes.ftSm}vw;
            }
          }
        }
      }
    }

    &__description {
      p {
        font-size: ${props => props.theme.sizes.ftR}px;
        color: ${props => props.theme.theme.mode.fontV2};
        line-height: ${props => props.theme.sizes.ftR+10}px;
        font-weight: 500;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftRm}vw;
          line-height: ${props => props.theme.sizes.ftRm+2}vw;
        }
      }
    }
  }
`;
