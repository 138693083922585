// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// STYLEs
import { Banner } from "./styles";

export function HomeBanner() {
  const { t } = useTranslate();

  return (
    <Banner id="banner">
      <div className="BannerContainer">
        <div className="BannerContainer__details">
          <div className="BannerDetails">
            <div className="BannerDetails__subtitle">
              <h2>{t("home").banner?.details.subtitle}</h2>
            </div>

            <div className="BannerDetails__title">
              <h1 
                dangerouslySetInnerHTML={{
                  __html: t("home").banner?.details.title,
                }} 
              />
            </div>

            <div className="BannerDetails__text">
              <p 
                dangerouslySetInnerHTML={{
                  __html: t("home").banner?.details.about,
                }}
              />
            </div>
          </div>
        </div>

        <div className="BannerContainer__image">
          <img 
            src={t("home").banner?.image} 
            alt="" 
          />
        </div>
      </div>
      <div className="BannerBG" />
    </Banner>
  );
}
