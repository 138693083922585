// STYLEs
import { SwitchContainer } from "./styles";

export interface ISwicthProps {
  value: boolean;
  onChecked: any;
}

export function Swicth({ value, onChecked }: ISwicthProps) {
  return (
    <SwitchContainer checked={value ? 1 : 0}>
      <div 
        className="SwitchContainer"
        onClick={onChecked}
      >
        <span />
      </div>
    </SwitchContainer>
  );
}
