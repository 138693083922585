// LIBs
import styled from "styled-components";

export const TabLeftBarDash = styled.aside<{ show: number }>`
  min-width: 400px;
  height: auto;
  min-height: 100vh;
  background-color: ${props => props.theme.theme.mode.bg};
  border-right: 2px solid ${props => props.theme.theme.green};
  transition: all ease 0.5s;

  display: flex;
  flex-direction: column;

  @media ${props => props.theme.screens.tb} {
    max-width: 100%;
    min-width: 100%;
    transform: translateX(-100%);
    overflow-y: auto;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    ${props => props.show === 1 ? `
      transform: translateX(0);
    ` : ""}
  }

  .TabLeftBarDash {
    padding: 40px 0;
    
    flex: 1;
    display: flex;
    flex-direction: column;

    @media ${props => props.theme.screens.tb} {
      padding-top: 80px;
    }

    &__close {
      display: none;

      @media ${props => props.theme.screens.tb} {
        display: block;

        position: absolute;
        top: 20px;
        right: 20px;
      }

      button {
        padding: 10px;

        display: flex;
        justify-content: center;
        align-items: center;
        
        svg {
          font-size: ${props => props.theme.sizes.ftBII}px;
          color: ${props => props.theme.theme.mode.font};

          @media ${props => props.theme.screens.mbB} {
            font-size: ${props => props.theme.sizes.ftBIIm}vw;
            color: ${props => props.theme.theme.mode.font};
          }
        }
      }
    }

    &__logo {
      width: 100%;
      margin-bottom: 40px;

      display: flex;
      justify-content: center;
      
      a {
        width: fit-content;

        h1, span {
          font-size: ${props => props.theme.sizes.ftBIII}px;
          color: ${props => props.theme.theme.mode.font};
          font-weight: 500;

          @media ${props => props.theme.screens.mbB} {
            font-size: ${props => props.theme.sizes.ftBIIIm * 2}vw;
          }

          span {
            font-size: ${props => props.theme.sizes.ftBII}px;
            color: ${props => props.theme.theme.green};
            font-family: 'Handjet';
          }
        }
      }
    }

    &__nav {
      flex: 1;
      margin-bottom: 40px;

      nav {
        ul {
          display: flex;
          flex-direction: column;
          gap: 20px;

          li {
            &.active-link {
              a {
                &::before {
                  width: 5px;
                  background-color: ${props => props.theme.theme.green};
                  opacity: 1;
                }

                div {
                  width: 100%;
                  background-color: ${props => props.theme.theme.green+"66"};

                  svg {
                    color: ${props => props.theme.theme.green};
                  }

                  p {
                    color: ${props => props.theme.theme.green};
                  }
                }
              }
            }

            &:hover {
              a {
                div {
                  svg {
                    color: ${props => props.theme.theme.green};
                  }

                  p {
                    color: ${props => props.theme.theme.green};
                  }
                }
              }
            }

            a {
              padding: 0 20px;

              display: flex;

              position: relative;

              &::before {
                content: '';
                width: 0;
                height: 100%;
                display: block;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                opacity: 0;
                transition: all ease 0.5s;

                position: absolute;
                left: 0;
              }

              div {
                width: 50%;
                border-radius: 10px;
                padding: 15px 20px;
                transition: all ease 0.5s;

                display: flex;
                align-items: center;
                gap: 10px;

                svg {
                  font-size: ${props => props.theme.sizes.ftBII + 10}px;
                  color: ${props => props.theme.theme.mode.font};
                  transition: all ease 0.5s;

                  @media ${props => props.theme.screens.mbB} {
                    font-size: ${props => props.theme.sizes.ftBIIm + 2}vw;
                  }
                }

                p {
                  font-size: ${props => props.theme.sizes.ft}px;
                  text-transform: uppercase;
                  color: ${props => props.theme.theme.mode.font};
                  font-weight: 600;
                  transition: all ease 0.5s;

                  @media ${props => props.theme.screens.mbB} {
                    font-size: ${props => props.theme.sizes.ftm}vw;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__footer {
      padding: 0 20px;

      display: flex;
      justify-content: center;
      align-items: center;

      p {
        font-size: ${props => props.theme.sizes.ftM}px;
        color: ${props => props.theme.theme.mode.font};
        font-weight: 500;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftMm}vw;
        }
      }
    }
  }
`;
