// LIBs
import styled from "styled-components";

export const Data = styled.div`
  .Data {
    &__header {
      margin-bottom: 30px;

      h2 {
        font-size: ${props => props.theme.sizes.ftBI}px;
        font-weight: 600;
        color: ${props => props.theme.theme.mode.font};
        word-break: break-all;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftBIm}vw;
        }
      }

      &::after {
        content: '';
        max-width: 75px;
        width: 100%;
        height: 3px;
        background-color: ${props => props.theme.theme.green};
        margin-top: 10px;

        display: block;
      }
    }

    &__details {
      margin-bottom: 30px;

      display: flex;
      justify-content: space-between;

      div {
        p {
          font-size: ${props => props.theme.sizes.ftM}px;
          font-weight: 600;
          color: ${props => props.theme.theme.mode.font};
          margin-bottom: 5px;

          @media ${props => props.theme.screens.mbB} {
            font-size: ${props => props.theme.sizes.ftMm}vw;
          }
        }

        span {
          font-size: ${props => props.theme.sizes.ft}px;
          color: ${props => props.theme.theme.mode.fontV2};

          @media ${props => props.theme.screens.mbB} {
            font-size: ${props => props.theme.sizes.ftm}vw;
          }
        }

        &:last-child {
          p {
            color: ${props => props.theme.theme.green};
          }
        }
      }
    }

    &__info {
      h2 {
        font-size: ${props => props.theme.sizes.ftB}px;
        font-weight: 600;
        color: ${props => props.theme.theme.mode.font};
        word-break: break-all;
        margin-bottom: 10px;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftBm}vw;
        }
      }

      p {
        line-height: ${props => props.theme.sizes.ftR + 10}px;
        color: ${props => props.theme.theme.mode.font};
        text-transform: uppercase;

        @media ${props => props.theme.screens.mbB} {
          line-height: ${props => props.theme.sizes.ftRm + 2}vw;
        }
      }
    }
  }
`;
