// LIBs
import { ReactNode } from "react";
import { useNavigate, Link } from "react-router-dom";

// ICONs
import { FiChevronLeft, FiMenu } from "react-icons/fi";

// STYLEs
import { GridDashLight } from "./styles";

interface IGridDashLightLayoutProps {
  onShow: any;
  children: ReactNode;
}

export function GridDashLightLayout({ onShow, children }: IGridDashLightLayoutProps) {
  const navigate = useNavigate();

  return (
    <GridDashLight>
      <div className="GridDashLightHeader">
        <button
          className="GridDashLightHeader__goBack"
          onClick={() => {
            navigate(-1);
          }}
        >
          <FiChevronLeft />
        </button>
        <Link to="/dashboard">
          <h1>JSNinja <span>Platform</span></h1>
        </Link>
        <button 
          className="GridDashLightHeader__menu"
          onClick={onShow}
        >
          <FiMenu />
        </button>
      </div>

      <div className="GridDashLight">
        {children}
      </div>
    </GridDashLight>
  );
}
