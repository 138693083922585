// LIBs
import { useState } from "react";
import { toast } from "react-toastify";

// COMPONENTs
import { Input } from "../../../components/Input"; 
import { Button } from "../../../components/Button";

// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// ICONs
import { FiEye, FiEyeOff } from "react-icons/fi";

// STYLEs
import { Login } from "./styles";

interface ILoginProps {
  onSend: any;
}

export function SignInLogin({ onSend }: ILoginProps) {
  const { t } = useTranslate();

  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ hidePass, setHidePass ] = useState(true);
  const [ load, setLoad ] = useState<boolean>(false);

  const send = () => {
    setLoad(true);

    if (email.trim() && password.trim()) {
      onSend(email, password);
    } else {
      toast.warn(t("signIn").content?.inputs_invalid);
    }

    setTimeout(() => setLoad(false), 1000);
  }

  return (
    <Login load={load ? 1 : 0}>
      <div className="Login">
        <div className="Login__title">
          <h1 
            dangerouslySetInnerHTML={{
              __html: t("signIn").content?.title
            }}
          />
        </div>

        <div className="Login__text">
          <p>{t("signIn").content?.text}</p>
        </div>

        <div className="Login__form">
          <Input 
            label={t("signIn").content?.email_input.label}
            value={email}
            onChange={(event: any) => {
              setEmail(event.target.value);
            }}
            placeholder={t("signIn").content?.email_input.placeholder}
          />
          <Input 
            type={hidePass ? "password" : "text"}
            label={t("signIn").content?.password_input.label} 
            value={password}
            onChange={(event: any) => {
              setPassword(event.target.value);
            }}
            placeholder={t("signIn").content?.password_input.placeholder}
            icon={{
              right: {
                content: (
                  <button 
                    className="Login__form_button"
                    onClick={() => {
                      setHidePass(!hidePass);
                    }}
                  >
                    { hidePass &&
                      <FiEye />
                    }

                    { !hidePass &&
                      <FiEyeOff  />
                    }
                  </button>
                ),
                padding: 44,
              }
            }}
          />
        </div>

        <div className="Login__button">
          <Button 
            title={t("signIn").content?.btn_label}
            onClick={send}
            shadowGreenBlur 
            load={load}
          />
        </div>
      </div>
    </Login>
  );
}
