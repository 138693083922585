// LIBs
import { ReactNode } from "react";
import InputMask from "react-input-mask";

// STYLEs
import { InputContainer } from "./styles";

export type InputTypeTypes = "text" | "password" | "textarea";

export interface IInputIconPositionsTypes {
  content: ReactNode;
  padding: number;
}

export interface IInputIconTypes {
  left?: IInputIconPositionsTypes;
  right?: IInputIconPositionsTypes;
}

export interface IInputProps {
  label: string;
  value: string;
  onChange: any;
  error?: string;
  mask?: string;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
  type?: InputTypeTypes;
  icon?: IInputIconTypes;
}

export function Input({ 
  label, 
  value, 
  onChange, 
  error,
  mask,
  className = "",
  placeholder = "",
  readOnly = false, 
  type = "text", 
  icon 
}: IInputProps) {
  return (
    <InputContainer 
      className={className} 
      read={readOnly ? 1 : 0}
      error={error ? 1 : 0}
    >
      <p>{label}</p>
      <div className="InputContainer">
        { type !== "textarea" &&
          <>
            { (icon && icon.left) &&
              <div className="InputContainer__icon InputContainer__icon--left">
                {icon.left.content}
              </div>
            }
            { !mask 
                ? <input 
                    type={type} 
                    value={value}
                    onChange={onChange}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    style={{
                      paddingLeft: (icon && icon.left) ? icon.left.padding : 0,
                      paddingRight: (icon && icon.right) ? icon.right.padding : 0
                    }}
                  />
                : <InputMask 
                    mask={mask!}
                    value={value}
                    onChange={onChange}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    style={{
                      paddingLeft: (icon && icon.left) ? icon.left.padding : 0,
                      paddingRight: (icon && icon.right) ? icon.right.padding : 0
                    }}
                  />
            }
            { (icon && icon.right) &&
              <div className="InputContainer__icon InputContainer__icon--right">
                {icon.right.content}
              </div>
            }
          </>
        }
        { type === "textarea" &&
          <textarea
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
          />
        }
      </div>
      { error &&
        <span>{error}</span>
      }
    </InputContainer>
  );
}
