// LIBs
import styled from "styled-components"

export const SwitchContainer = styled.div<{ checked: number }>`
  margin: 0 10px;
  
  .SwitchContainer {
    width: 60px;
    height: 30px;
    border-radius: 15px;
    background-color: ${props => props.theme.theme.mode.bgV2};
    cursor: pointer;
    transition: all ease 0.5s;

    position: relative;

    ${props => props.checked === 1 ? `
      background-color: ${props.theme.theme.green};
    ` : ""}

    span {
      width: 30px;
      height: 30px;
      background-color: ${props => props.theme.theme.mode.fontV2};
      border-radius: 50%;
      transform: scale(1.2);
      transition: all ease 0.5s;

      position: absolute;
      left: 0;

      ${props => props.checked === 1 ? `
        transform: translateX(-100%) scale(1.2);

        left: 100%;
      ` : ""}
    }
  }
`;
