const environment = process.env.NODE_ENV || "development";
const port = process.env.PORT || 3000;

interface IAppConfigTypes {
  [key: string]: {
    baseURL: string;
    pathname: string;
    requestURL: string;
  }
}

const appConfig: IAppConfigTypes = {
  development: {
    baseURL: `http://localhost:${port}`,
    pathname: "/",
    requestURL: "http://localhost/projetos/backend/jsninja_platform",
  },
  production: {
    baseURL: "https://jsninjajourney.com.br",
    pathname: "/",
    requestURL: "https://api.jsninjajourney.com.br",
  },
};

export default appConfig[environment];
