// STYLEs
import styled from "styled-components";

export const FormContainer = styled.div<{ load: number }>`
  .FormContainer {
    &__header {
      margin-bottom: 30px;

      h2 {
        font-size: ${props => props.theme.sizes.ftBI}px;
        font-weight: 600;
        color: ${props => props.theme.theme.mode.font};
        word-break: break-all;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftBIm}vw;
        }
      }

      &::after {
        content: '';
        max-width: 75px;
        width: 100%;
        height: 3px;
        background-color: ${props => props.theme.theme.green};
        margin-top: 10px;

        display: block;
      }
    }

    &__inputs {
      margin-bottom: 30px;

      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      ${props => props.load === 1 ? `
        opacity: 0.5;
        pointer-events: none;
      ` : ""}

      label {
        min-width: calc(50% - 10px);

        flex: 1;

        @media ${props => props.theme.screens.mbB} {
          min-width: 100%;
        }
      }
    }

    &__buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      @media ${props => props.theme.screens.mbB} {
        grid-template-columns: 1fr;
      }
    
      button {
        width: 100%;
      }
    }
  }
`;
