// LIBs
import { useMemo, useState, useEffect } from "react";
import Slider from "react-slick";

// COMPONENTs
import { Button } from "../../../components/Button";

// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// STYLEs
import { ProjectContainer } from "./styles";

// ICONs
import { FiGithub, FiYoutube, FiDownload, FiLink2, FiFigma } from "react-icons/fi";

interface IHomeProjectProps {
  indexOfProject: number | null;
}

let sliderInterval: any;

export function HomeProject({ indexOfProject }: IHomeProjectProps) {
  const { t, language } = useTranslate();

  const [ index, setIndex ] = useState<number>();
  const [ currentSlide, setCurrentSlider ] = useState<number>(0);

  const projectData: any = useMemo(() => {
    let value  = null;
    if (indexOfProject !== null) {
      value = t("home").explorer?.projects[indexOfProject];
      setIndex(indexOfProject);
    } else if (indexOfProject === null && index !== undefined) {
      value = t("home").explorer?.projects[index];
    }
    return value;
  }, [indexOfProject, language]);

  useEffect(() => {
    if (indexOfProject !== null && projectData && projectData.images.length > 0) {
      sliderInterval = setInterval(() => {
        setCurrentSlider(current => current >= projectData.images.length - 1 ? 0 : current + 1);
      }, 6000);
    } else {
      clearInterval(sliderInterval);
      setCurrentSlider(0);
    }
  }, [indexOfProject])

  return (
    <ProjectContainer>
      <div className="ProjectContainer">
        <div className="ProjectContainer__banner">
          { (projectData && projectData.images.length > 0) &&
            projectData.images.map((image: string, index: number) => (
              <img 
                src={image}
                key={index}
                className={currentSlide === index ? "active-slider" : ""}
                alt=""
              />
            ))
          }
        </div>

        <div className="ProjectContainer__details">
          <div className="ProjectDetails">
            { projectData &&
              <div className="ProjectDetails__title">
                <h3>{projectData.name}</h3>
              </div>
            }

            { projectData &&
              <div className="ProjectDetails__about">
                <p>{projectData.about}</p>
              </div>
            }

            <div className="ProjectDetails__buttons">
              { (projectData && projectData.link_github) &&
                <Button 
                  type="link-external"
                  to={projectData.link_github}
                  iconLeft={<FiGithub />}
                />
              }
              { (projectData && projectData.reference) &&
                <Button 
                  type="link-external"
                  to={projectData.reference}
                  iconLeft={<FiYoutube  />}
                />
              }
              { (projectData && projectData.link_download) &&
                <Button 
                  type="link-external"
                  to={projectData.link_download}
                  iconLeft={<FiDownload  />}
                />
              }
              { (projectData && projectData.link_preview) &&
                <Button 
                  type="link-external"
                  to={projectData.link_preview}
                  iconLeft={<FiLink2  />}
                />
              }
              { (projectData && projectData.link_figma) &&
                <Button 
                  type="link-external"
                  to={projectData.link_figma}
                  iconLeft={<FiFigma  />}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </ProjectContainer>
  );
}
