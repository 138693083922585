// LIBs
import { BrowserRouter, Routes, Route } from "react-router-dom";

// CONFIG
import appConfig from "./app";

// LAYOUT
import { ToastLayout } from "../../layout/Toast";

// TEMPLATE
import { DashboardTemplate } from "../../template/dashboard";
import { DashboardLightTemplate } from "../../template/dashboard-light";
import { PortfolioTemplate } from "../../template/portfolio";

// VIEWs
import { ContactsView } from "../../views/contacts";
import { DashboardView } from "../../views/dashboard";
import { HomeView } from "../../views/home";
import { MyProfileView } from "../../views/my-profile";
import { SettingsView } from "../../views/settings";
import { SignInView } from "../../views/sign-in";
import { UsersView } from "../../views/users";

export function Router() {
  const TemplatePortfolio = () => (
    <PortfolioTemplate>
      <HomeView />
    </PortfolioTemplate>
  );

  return (
    <BrowserRouter basename={appConfig.pathname}>
      <Routes>
        {/* PUBLIC ROUTES */}
        <Route path="/" element={<TemplatePortfolio />} />
        <Route path="/dashboard/sign-in" element={<SignInView />} />

        {/* PRIVATE ROUTES */}
        <Route 
          path="/dashboard" 
          element={(
            <DashboardTemplate>
              <DashboardView />
            </DashboardTemplate>
          )} 
        />
        <Route 
          path="/dashboard/users" 
          element={(
            <DashboardTemplate>
              <UsersView />
            </DashboardTemplate>
          )} 
        />
        <Route 
          path="/dashboard/contacts" 
          element={(
            <DashboardTemplate>
              <ContactsView />
            </DashboardTemplate>
          )} 
        />
        <Route 
          path="/dashboard/my-profile" 
          element={(
            <DashboardLightTemplate>
              <MyProfileView />
            </DashboardLightTemplate>
          )} 
        />
        <Route 
          path="/dashboard/settings" 
          element={(
            <DashboardLightTemplate>
              <SettingsView />
            </DashboardLightTemplate>
          )} 
        />

        {/* NOT FOUND ROUTE */}
        <Route path="*" element={<TemplatePortfolio />} />
      </Routes>
      <ToastLayout />
    </BrowserRouter>
  );
}
