// LIBs
import styled from "styled-components";

export const ProjectContainer = styled.div`
  max-width: 450px;
  width: 100%;

  .ProjectContainer {
    width: 100%;
    border-radius: 10px;
    background-color: ${props => props.theme.theme.mode.bg};
    overflow: hidden;
    border-bottom: 4px solid ${props => props.theme.theme.green};
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);

    &__banner {
      width: 100%;
      height: 83vw;
      max-height: 375px;
      background-color: ${props => props.theme.theme.mode.bgV2};
      overflow: hidden;

      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        opacity: 0;
        transition: all ease 0.5s;

        position: absolute;
        top: 0;
        left: 0;

        &.active-slider {
          opacity: 1;
        }
      }
    }

    &__details {
      max-height: calc(80vh - 375px);
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        background-color: #F4F4F4;
      }
      
      &::-webkit-scrollbar {
        width: 6px;
        background: #F4F4F4;
      }

      &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.theme.green};
      }

      .ProjectDetails {
        padding: 20px;

        &__title {
          margin-bottom: 20px;

          h3 {
            font-size: ${props => props.theme.sizes.ftBII}px;
            font-weight: 600;
            color: ${props => props.theme.theme.green};
            margin-bottom: 10px;
          }

          &::after {
            content: '';
            width: 15%;
            height: 3px;
            background-color: ${props => props.theme.theme.green};

            display: block;
          }
        }

        &__about {
          margin-bottom: 40px;

          p {
            font-size: ${props => props.theme.sizes.ft}px;
            color: ${props => props.theme.theme.mode.font};
            line-height: ${props => props.theme.sizes.ft + 10}px;
          }
        }

        &__buttons {
          display: flex;
          gap: 20px;

          a {
            padding: 20px;
          }
        }
      }
    }
  }
`;
