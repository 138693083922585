// LIBs
import styled from "styled-components";

export const GridDashLight = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 40px 0;
  height: auto;
  background-color: ${props => props.theme.theme.mode.bg};

  .GridDashLightHeader {
    margin-bottom: 60px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    &__goBack {
      padding: 13px;
      background-color: ${props => props.theme.theme.green};
      border-radius: 50%;
      border: 2px solid ${props => props.theme.theme.green};
      transition: all ease 0.8s;

      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      left: 20px;

      @media ${props => props.theme.screens.mbB} {
        padding: 2.8vw;
      }

      svg {
        font-size: ${props => props.theme.sizes.ftBII}px;
        color: ${props => props.theme.theme.mode.bg};
        transition: all ease 0.8s;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftBIIm}vw;
        }
      }

      &:hover {
        background-color: transparent;

        svg {
          color: ${props => props.theme.theme.green};
        }
      }
    }

    h1, span {
      font-size: ${props => props.theme.sizes.ftBIII}px;
      color: ${props => props.theme.theme.mode.font};
      font-weight: 500;

      @media ${props => props.theme.screens.mbB} {
        font-size: ${props => props.theme.sizes.ftBIIIm * 2}vw;
      }

      span {
        font-size: ${props => props.theme.sizes.ftBII}px;
        color: ${props => props.theme.theme.green};
        font-family: 'Handjet';
      }
    }

    &__menu {
      padding: 10px;

      display: none;
      justify-content: center;
      align-items: center;

      position: absolute;
      right: 20px;
      
      @media ${props => props.theme.screens.tb} {
        display: flex;
      }

      svg {
        font-size: ${props => props.theme.sizes.ftBII}px;
        color: ${props => props.theme.theme.mode.font};
      }
    }
  }

  .GridDashLight {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    display: flex;
    gap: 40px;

    &__button {
      position: fixed;
      top: 30px;
      left: 30px;
    }
  }
`;
