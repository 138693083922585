// LIBs
import styled from "styled-components";

export const CardExplorerContainer = styled.div`
  .CardExplorerContainer {
    border-radius: 10px;
    overflow: hidden;

    position: relative;

    &__image {
      width: 100%;

      img {
        width: 100%;
      }
    }

    &__info {
      width: 100%;
      padding: 32px 20px;
      /* background: linear-gradient(0deg, ${props => props.theme.theme.mode.green} 19.79%, transparent 72.4%); */
      text-align: center;

      position: absolute;
      bottom: 0;
      left: 0;

      span {
        font-size: ${props => props.theme.sizes.ftS}px;
        text-transform: uppercase;
        color: ${props => props.theme.theme.green};
        font-weight: 500;
        margin-bottom: 5px;

        display: block;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftSm}vw;
        }
      }

      p {
        font-size: ${props => props.theme.sizes.ft}px;
        text-transform: uppercase;
        color: #FFF;
        text-shadow: 0 0 4px rgba(0,0,0,0.8);
        font-weight: 500;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftm}vw;
        }
      }
    }
  }
`;
