// LIBs
import styled from "styled-components";

export const SendFormContainer = styled.div`
  max-width: 375px;
  width: 100%;
  background-color: ${props => props.theme.theme.mode.bg};
  border-radius: 10px;
  border-bottom: 3px solid ${props => props.theme.theme.green};

  .SendFormContainer {
    padding: 60px 30px;
    padding-bottom: 80px;

    &__icon {
      width: fit-content;
      border-radius: 50%;
      padding: 20px;
      background-color: ${props => props.theme.theme.success};
      margin: 0 auto;
      margin-bottom: 40px;

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: ${props => props.theme.sizes.ftXIII * 3}px;
        color: ${props => props.theme.theme.mode.font};
      }
    }

    &__title {
      margin-bottom: 20px;
      text-align: center;

      p {
        font-size: ${props => props.theme.sizes.ftBI}px;
        font-weight: 500;
        color: ${props => props.theme.theme.mode.font};

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftBIm}vw;
        }
      }
    }

    &__text {
      text-align: center;
      p {
        font-size: ${props => props.theme.sizes.ftR}px;
        color: ${props => props.theme.theme.mode.font};
        line-height: ${props => props.theme.sizes.ftR + 10}px;
        text-transform: uppercase;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftRm}vw;
          line-height: ${props => props.theme.sizes.ftRm + 2}vw;
        }
      }
    }
  }
`;
