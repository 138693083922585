// DATA
import { menuDT_ptBR as menuDT_home } from "./data/home/menuDT_pt-br";
import { bannerDT_ptBR as bannerDT_home } from "./data/home/bannerDT_pt-br";
import { explorerDT_ptBR as explorerDT_home } from "./data/home/explorerDT_pt-br";
import { servicesDT_ptBR as servicesDT_home } from "./data/home/servicesDT_pt-br";
import { technologiesDT_ptBR as technologiesDT_home } from "./data/home/technologiesDT_pt-br";
import { contactDT_ptBR as contactDT_home } from "./data/home/contactDT_pt-br";
import { footerDT_ptBR as footerDT_home } from "./data/home/footerDT_pt-br";
import { contentDT_ptBR as contentDT_signIn } from "./data/sign-in/contentDT_pt-br";
import { contentDT_ptBR as contentDT_platform } from "./data/platform/contentDT_pt-br";
import { contentDT_ptBR as formDT_users } from "./data/users/contentDT_pt-br";
import { inputsDT_ptBR as inputsDT_common } from "./data/common/inputsDT_pt-br";
import { requestDT_ptBR as requestDT_common } from "./data/common/requestDT_pt-br";
import { deleteDT_ptBR as deleteDT_common } from "./data/common/deleteDT_pt-br";
import { contentDT_ptBR as formDT_contacts } from "./data/contacts/contentDT_pt-br";
import { formDT_ptBR as formDT_myProfile } from "./data/my-profile/formDT_pt-br";

export const ptBR = {
  title: "Olá Mundo!",
  home: {
    menu: menuDT_home,
    banner: bannerDT_home,
    explorer: explorerDT_home,
    services: servicesDT_home,
    technologies: technologiesDT_home,
    contact: contactDT_home,
    footer: footerDT_home,
  },
  signIn: {
    content: contentDT_signIn,
  },
  platform: {
    content: contentDT_platform,
  },
  users: {
    content: formDT_users,
  },
  contacts: {
    content: formDT_contacts,
  },
  myProfile: {
    form: formDT_myProfile,
  },
  common: {
    inputs: inputsDT_common,
    request: requestDT_common,
    delete: deleteDT_common,
  },
};