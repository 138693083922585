// LIBs
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

// COMPONENTs
import { Modal } from "../../components/Modal";

// CONTAINERs
import { ContactsList } from "../../containers/contacts/list";
import { ContactsData } from "../../containers/contacts/data";
import { CommonDelete } from "../../containers/common/delete";

// CONFIG
import { useToolkitSelector } from "../../core/config/store";

// CONSTANTs
import { useTranslate } from "../../core/constants/useTranslate";

// REDUCERs
import { getUser } from "../../core/reducers/user-reducer/selector";

// UTILs
import { getContacts, getContact, deleteContact } from "../../core/utils/Api";

// TYPEs
import { IContactsMinTypes, IContactsTypes } from "../../types/contactsTypes";

export function ContactsView() {
  const userLogged = useToolkitSelector(getUser);

  const { t } = useTranslate();

  const [ contactId, setContactId ] = useState<string>("");
  const [ contact, setContact ] = useState<IContactsTypes | null>(null);
  const [ contacts, setContacts ] = useState<IContactsMinTypes[]>([]);

  useEffect(() => {
    fetchContacts();
  }, [])

  const fetchContacts = async () => {
    const req = await getContacts();

    if (req.data) {
      setContacts(req.data.contacts);
    } else {
      toast.error(t("common").request[req.error.message]);
    }
  }

  const fetchContact = async (id: string) => {
    const req = await getContact(id);

    if (req.data) {
      setContact(req.data.contact);
    } else {
      toast.error(t("common").request[req.error.message]);
    }
  }

  const delContact = async () => {
    const req = await deleteContact(contactId);
    if (!req.error) {
      fetchContacts();
      if (contactId === contact?.id) setContact(null);
      setContactId("");
      toast.success(t("contacts").content?.contact_deleted);
    } else {
      toast.error(t("common").request[req.error.message]);
    }
  }

  const access = userLogged ? JSON.parse(userLogged.config_hierarchy.access) : null;

  return (
    <main className="ContactsView">
      { (access && access.contacts && access.contacts.includes("READ")) &&
        <>
          <ContactsList 
            data={contacts}
            onView={fetchContact}
            onDelete={(id: string) => {
              setContactId(id);
            }}
          />
          <ContactsData 
            data={contact}
          />
        </>
      }
      { (access && access.contacts && access.contacts.includes("DELETE")) &&
        <Modal
          show={!!contactId}
          onHide={() => {
            setContactId("");
          }}
        >
          <CommonDelete 
            phrase={t("contacts").content?.phrase}
            onDelete={delContact}
            onCancel={() => {
              setContactId("");
            }}
          />
        </Modal>
      }
    </main>
  );
}
