// LIBs
import styled from "styled-components";

export const InputContainer = styled.label<{ read: number, error: number }>`
  ${props => props.read ? `
    opacity: 0.5;
    transition: all ease 0.5s;
    pointer-events: none;
  ` : ""}

  &:hover {
    p {
      color: ${props => props.theme.theme.green};
    }

    .InputContainer {
      input,
      textarea {
        color: ${props => props.theme.theme.green};
        border-color: ${props => props.theme.theme.green};

        &::placeholder {
          color: ${props => props.theme.theme.green};
        }
      }

      span {
        color: ${props => props.theme.theme.green};
      }
    }

    >span {
      color: ${props => props.theme.theme.green};
    }
  }

  p {
    font-size: ${props => props.theme.sizes.ft}px;
    color: ${props => props.theme.theme.mode.font};
    font-weight: 200;
    margin-bottom: 10px;
    transition: all ease 0.5s;

    @media ${props => props.theme.screens.mbB} {
      font-size: ${props => props.theme.sizes.ftm}vw;
    }

    ${props => props.error ? `
      color: ${props.theme.theme.danger};
    ` : ""}
  }

  .InputContainer {
    display: flex;
    align-items: center;

    position: relative;

    input,
    textarea {
      min-width: 155px;
      width: 100%;
      padding: 15px 0;
      border-bottom: 1px solid ${props => props.theme.theme.mode.font};
      font-size: ${props => props.theme.sizes.ft}px;
      color: ${props => props.theme.theme.mode.font};
      transition: all ease 0.5s;

      @media ${props => props.theme.screens.mbB} {
        font-size: ${props => props.theme.sizes.ftm}vw;
      }

      &::placeholder {
        transition: all ease 0.5s;

        ${props => props.error ? `
          color: ${props.theme.theme.danger};
        ` : ""}
      }

      ${props => props.error ? `
        color: ${props.theme.theme.danger};
        border-color: ${props.theme.theme.danger};
      ` : ""}
    }

    textarea {
      height: 200px;
    }

    &__icon {
      position: absolute;

      &--left {
        left: 10px;
      }

      &--right {
        right: 10px;
      }
    }
  }

  >span {
    margin-top: 10px;
    font-size: ${props => props.theme.sizes.ftS}px;
    color: ${props => props.theme.theme.danger};
    transition: all ease 0.5s;

    display: block;
  }
`;
