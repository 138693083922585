// LIBs
import styled from "styled-components";

export const SelectContainer = styled.label`
  &:hover {
    p {
      color: ${props => props.theme.theme.green};
    }

    .SelectContainer {
      select {
        color: ${props => props.theme.theme.green};
        border-color: ${props => props.theme.theme.green};
      }

      span {
        color: ${props => props.theme.theme.green};
      }
    }
  }

  p {
    font-size: ${props => props.theme.sizes.ft}px;
    color: ${props => props.theme.theme.mode.font};
    font-weight: 200;
    margin-bottom: 10px;
    transition: all ease 0.5s;

    @media ${props => props.theme.screens.mbB} {
      font-size: ${props => props.theme.sizes.ftm}vw;
    }
  }

  .SelectContainer {
    display: flex;
    align-items: center;

    position: relative;

    select {
      min-width: 155px;
      width: 100%;
      padding: 15px 0;
      border: 0;
      outline: 0;
      border-bottom: 1px solid ${props => props.theme.theme.mode.font};
      background-color: transparent;
      font-size: ${props => props.theme.sizes.ft}px;
      color: ${props => props.theme.theme.mode.font};
      -webkit-appearance: none;
      -moz-appearance: none;
      transition: all ease 0.5s;
      cursor: pointer;

      @media ${props => props.theme.screens.mbB} {
        font-size: ${props => props.theme.sizes.ftm}vw;
      }

      option {
        color: ${props => props.theme.theme.mode.font};
        background-color: ${props => props.theme.theme.mode.bg};
        padding: 5px 10px;
      }
    }

    span {
      font-size: ${props => props.theme.sizes.ftBII}px;
      color: ${props => props.theme.theme.mode.font};
      pointer-events: none;
      transition: all ease 0.5s;

      position: absolute;
      right: 0;

      @media ${props => props.theme.screens.mbB} {
        font-size: ${props => props.theme.sizes.ftBIIm}vw;
      }
    }
  }
`;
