// LIBs
import styled from "styled-components";

export const Form = styled.div`
  .MyProfileForm {
    &__header {
      margin-bottom: 40px;

      h1 {
        font-size: ${props => props.theme.sizes.ftXI}px;
        color: ${props => props.theme.theme.mode.font};
        font-weight: 600;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftXIm}vw;
        }

        &::after {
          content: '';
          width: 100px;
          height: 3px;
          background-color: ${props => props.theme.theme.green};
          margin-top: 5px;

          display: block;
        }
      }
    }

    &__profile {
      margin-bottom: 20px;

      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        font-size: ${props => props.theme.sizes.ftM}px;
        color: ${props => props.theme.theme.mode.font};
        font-weight: 200;
        margin-bottom: 10px;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftMm}vw;
        }
      }

      label {
        cursor: pointer;

        span {
          width: 200px;
          height: 200px;
          background-color: ${props => props.theme.theme.mode.bgV2};
          border-radius: 20px;

          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            font-size: ${props => props.theme.sizes.ftBII * 2}px;
          }
        }

        img {
          width: 200px;
          height: 200px;
          border-radius: 20px;

          object-fit: cover;
          object-position: center;
        }

        input {
          display: none;
        }
      }
    }

    &__inputs {
      margin-bottom: 40px;

      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      @media ${props => props.theme.screens.mbB} {
        flex-direction: column;
      }

      label {
        flex: 1;
        min-width: calc(50% - 10px);
      }

      .MyProfileDetails {
        width: 100%;

        display: flex;
        justify-content: space-between;

        div {
          &:last-child {
            text-align: right;
          }

          p {
            margin-bottom: 5px;
            font-size: ${props => props.theme.sizes.ft}px;
            color: ${props => props.theme.theme.mode.fontV2};

            @media ${props => props.theme.screens.mbB} {
              font-size: ${props => props.theme.sizes.ftm}vw;
            }
          }

          span {
            font-size: ${props => props.theme.sizes.ftB}px;
            color: ${props => props.theme.theme.green};
            letter-spacing: 1px;

            @media ${props => props.theme.screens.mbB} {
              font-size: ${props => props.theme.sizes.ftBm}vw;
            }
          }
        }
      }
    }

    &__button {
      button {
        width: calc(50% - 10px);

        @media ${props => props.theme.screens.mbB} {
          width: 100%;
        }
      }
    }
  }
`;
