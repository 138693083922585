// LIBs
import { useState, useEffect } from "react";
import Slider from "react-slick";

// COMPONENTs
import { CardExplorer } from "../../../components/CardExplorer";
import { Select } from "../../../components/Select";
import { Button } from "../../../components/Button";

// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// STYLEs
import { Explorer, ExplorerSearchContainer } from "./styles";

interface IHomeExplorerProps {
  categoryProjects: number | string;
  categoryTechnologies: number | string;
  onChangeCategoryProjects: any;
  onChangeCategoryTechnologies: any;
  onClickProject: any;
}

export function HomeExplorer({ 
  categoryProjects, 
  categoryTechnologies,
  onChangeCategoryProjects,
  onChangeCategoryTechnologies,
  onClickProject,
}: IHomeExplorerProps) {
  const { t } = useTranslate();

  const [ projects, setProjects ] = useState(t("home").explorer?.projects);

  useEffect(() => {
    const categoriesProjects = [ ...t("home").explorer?.categories_projects ];
    const categoriesTechnologies = [ ...t("home").explorer?.categories_technologies ];

    setProjects(t("home").explorer?.projects.filter((el: any) => {
      let is = true;
      if (
        categoryProjects !== "0" &&
        !el.tags.includes(categoriesProjects[Number(categoryProjects)])
      ) {
        is = false;
      }
      if (
        categoryTechnologies !== "0" &&
        !el.technologies.includes(categoriesTechnologies[Number(categoryTechnologies)])
      ) {
        is = false;
      }
      if (is) {
        return el;
      }
    }));
  }, [categoryProjects, categoryTechnologies])

  const ExplorerSearch = () => (
    <ExplorerSearchContainer>
      <div className="ExplorerSearchContainer">
        <div className="ExplorerSearchContainer__type">
          <Select 
            label={t("home").explorer?.categories_projects_title} 
            value={`${categoryProjects}`}
            data={t("home").explorer?.categories_projects.map((category_project: string, index: number) => ({
              name: category_project,
              id: index,
            }))}
            onChange={(event: any) => {
              onChangeCategoryProjects(event.target.value);
            }}
          />
        </div>

        <div className="ExplorerSearchContainer__technologies">
          <Select 
            label={t("home").explorer?.categories_technologies_title} 
            value={`${categoryTechnologies}`}
            data={t("home").explorer?.categories_technologies.map((category_project: string, index: number) => ({
              name: category_project,
              id: index,
            }))}
            onChange={(event: any) => {
              onChangeCategoryTechnologies(event.target.value);
            }}
          />
        </div>
      </div>
    </ExplorerSearchContainer>
  );

  return (
    <Explorer id="explorer">
      <div className="ExplorerContainer">
        <div className="ExplorerContainer__details">
          <div className="ExplorerDetails">
            <div className="ExplorerDetails__title">
              <h2>{t("home").explorer?.title}</h2>
            </div>

            <div className="ExplorerDetails__description">
              <p>{t("home").explorer?.text}</p>
            </div>
          </div>
        </div>

        <ExplorerSearch />

        <div className="ExplorerContainer__list">
          <div className="ExplorerList">
            <div className="ExplorerList__header">

            </div>

            <div className="ExplorerList__slider">
              { projects.length > 0 ? (
                  <Slider
                    infinite={false}
                    slidesToShow={3}
                    slidesToScroll={1}
                    arrows={false}
                    focusOnSelect
                    dots
                    responsive={[
                      {
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 2,
                        },
                      },
                      {
                        breakpoint: 450,
                        settings: {
                          slidesToShow: 1.1,
                        },
                      },
                    ]}
                  >
                    {
                      projects.map((project: any, index: number) => (
                        <CardExplorer 
                          image={project.cover}
                          name={project.name}
                          newProject={project.new}
                          onClick={() => {
                            onClickProject(index);
                          }}
                          key={index}
                        />
                      ))
                    }
                  </Slider>
                ) : (
                  <div className="ExplorerList__slider--found">
                    <p className="ExplorerList__slider--alert">
                      {t("home").explorer?.found_projects}
                    </p>
                  </div>
                )
              }
            </div>

            <div className="ExplorerList__button">
              <Button 
                type="link-scroll" 
                to="services"
                title={t("home").explorer?.button_contact} 
                shadowGreenBlur
              />
            </div>
          </div>
        </div>
      </div>
    </Explorer>
  );
}
