// CONFIG
import { useToolkitSelector, useToolkitDispatch } from "../config/store";

// REDUCERs
import { LanguageTypes, setLanguage } from "../reducers/app-reducer";
import { getLanguage } from "../reducers/app-reducer/selector";

// LANGUAGEs
import { ptBR } from "./ptBR";
import { enUS } from "./enUS";

interface IAllLanguagesTypes {
  [key: string]: any;
};

const allLanguages: IAllLanguagesTypes = {
  "pt-BR": ptBR,
  "en-US": enUS,
};

export function useTranslate() {
  const dispatch = useToolkitDispatch();

  const language = useToolkitSelector(state => getLanguage(state));

  const t = (key: string) => {
    if (allLanguages[language]) {
      if (allLanguages[language][key]) {
        return allLanguages[language][key];
      }
    } else {
      console.error("Language not found!");
    }

    return key;
  }

  const changeLanguage = (lang: LanguageTypes) => {
    dispatch(setLanguage(lang));
  }

  return { t, language, changeLanguage };
}
