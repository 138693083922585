export const contentDT_ptBR = {
  title: "Lista de Usuários",
  table: {
    photo: "Foto de Perfil:",
    name: "Nome:",
    email: "E-mail:",
    actions: "Ações",
    none_actions: "Sem ações!",
  },
  subtitle: "Criar Novo Usuário",
  subtitle_edit: "Editar Usuário - userId",
  btn_create: "Criar Usuário",
  btn_edit: "Editar Usuário",
  btn_cancel: "Cancelar Seleção",
  modal_delete: {
    phrase: "Você deseja deletar o este usuário?",
    btn_delete: "Deletar",
    btn_cancel: "Cancelar",
  },
  user_created: "Usuário criado com sucesso!",
  user_updated: "Usuário editado com sucesso!",
  user_deleted: "Usuário deletado com sucesso!",
};
