export const contactDT_ptBR = {
  title: "Venha Conosco!",
  description: "Transforme sua Visão em Realidade - Desenvolvemos Websites e Apps que Impressionam. Junte-se à Revolução Digital!",
  label_name: "Nome:",
  placeholder_name: "Digite seu nome...",
  label_email: "E-mail:",
  placeholder_email: "Digite seu e-mail...",
  label_phone: "Telefone:",
  placeholder_phone: "Digite seu número de telefone...",
  label_subject: "Assunto:",
  placeholder_subject: "Digite o assunto...",
  label_text: "Sobre:",
  placeholder_text: "Digite sobre o seu projeto...",
  accept_conditions: "Você aceitar os ",
  accept_conditions_link: "termos de uso.",
  button_contact: "Solicitar Orçamento",
  success_title: "Formulário enviado com sucesso!",
  success_message: "Fique atento ao seu WhatsApp ou Caixa de E-mail estaremos entrando em contato em breve!",
  inputs_invalid: "Preencha todos os campos corretamente!",
  form_block: "Formulário bloqueado!",
  email_invalid: "E-mail inválido!",
  phone_invalid: "Telefone inválido!",
  text_invalid: "O campo precisa ter mais de qtdCarac caracteres!",
  modal_title: "Mensagem enviada!",
  modal_text: "Seu mensagem foi enviada com sucesso, em breve estaremos entrando em contato, fique de olho em sua caixa de E-mail!",
  form_request: {
    "Falta paramêtros na requisição!": "Falta paramêtros na requisição!",
  },
};