export const contentDT_enUS = {
  title: "User List",
  table: {
    photo: "Profile picture:",
    name: "Name:",
    email: "Email:",
    actions: "Actions",
    none_actions: "No actions!",
  },
  subtitle: "Create New User",
  subtitle_edit: "Edit User - userId",
  btn_create: "Create User",
  btn_edit: "Edit User",
  btn_cancel: "Cancel Selection",
  modal_delete: {
    phrase: "Do you want to delete this user?",
    btn_delete: "Delete",
    btn_cancel: "Cancel",
  },
  user_created: "User created successfully!",
  user_updated: "User edited successfully!",
  user_deleted: "User deleted successfully!",
};
