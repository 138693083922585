// ICONs
import { FaFigma, FaHtml5, FaCss3Alt, FaJs, FaPhp, FaReact, FaWordpress, FaGithub, FaTrello } from "react-icons/fa6";

export const servicesDT_enUS = {
  list: [
    {
      title: "Design Creation",
      text_resum: "We offer UI/UX design services for the development of your Web and Mobile applications!",
      text: "Unleash the maximum potential of your ideas with our UI/UX design services! We're here to turn your vision into reality, elevating the visual impact and user experience of your Web and Mobile applications. With cutting-edge technology and expertise in Figma, we guarantee a world-class design, aligned with your needs and always maintaining fluid and constant communication to capture every detail of your project. Let's create something amazing together!",
      technologies: [FaFigma],
    },
    {
      title: "Website Creation",
      text_resum: "We offer dynamic and static website creation services!",
      text: "Bring your online presence to life with our full range of website creation services! Whether for dynamic or static projects, we use an arsenal of cutting-edge technologies – HTML5, CSS3, JavaScript, PHP, ReactJs and Wordpress – to build your perfect digital platform. Our commitment goes beyond development, maintaining constant contact to understand your needs and ensuring that every detail is perfectly captured. We are ready to transform your vision into a functional and stunning website!",
      technologies: [FaHtml5, FaCss3Alt, FaJs, FaPhp, FaReact, FaWordpress],
    },
    {
      title: "Mobile Application Creation",
      text_resum: "We offer Android app creation services!",
      text: "Discover new horizons in the mobile world with our specialty in creating apps for Android! Using the power and insights of React Native, we shape your interactive and functional experiences. Our commitment goes beyond technical development, we prioritize constant communication to understand the essence of your project, ensuring that each functionality is carefully integrated. We are ready to transform your vision into a captivating and impactful app!",
      technologies: [FaReact],
    },
    {
      title: "Assistance and Monitoring",
      text_resum: "We offer advice for your project developed with us and we closely monitor the development!",
      text: "Rest assured while we take care of your project! In addition to offering ongoing assistance, our commitment includes closely monitoring developments. We use cutting-edge tools, such as Github and Trello, to ensure complete transparency and control at every stage. Our focus is to ensure not only technical quality, but also constant communication so that you feel like an integral part of the process. Count on us to follow you closely and take your project to the next level!",
      technologies: [FaGithub, FaTrello],
    },
  ],
  text_used: "TECHNOLOGIES USED:",
  button_text: "See about technologies",
};