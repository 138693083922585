// ICONs
import { FaFigma, FaHtml5, FaCss3Alt, FaJs, FaPhp, FaReact, FaWordpress, FaGithub, FaTrello } from "react-icons/fa6";

export const servicesDT_ptBR = {
  list: [
    {
      title: "Criação de <span>Designs</span>",
      text_resum: "Oferecemos serviços de UI/UX design, para o desenvolvimento de suas aplicações Web & Mobile!",
      text: "Desperte o potencial máximo das suas ideias com nossos serviços de UI/UX design! Estamos aqui para transformar sua visão em realidade, elevando o impacto visual e a experiência do usuário em suas aplicações Web & Mobile. Com tecnologia de ponta e expertise em Figma, garantimos um design de classe mundial, alinhado com suas necessidades e sempre mantendo uma comunicação fluída e constante para capturar cada detalhe do seu projeto. Vamos juntos criar algo incrível!",
      technologies: [FaFigma],
    },
    {
      title: "Criação de <span>Sites</span>",
      text_resum: "Oferecemos serviços de criação de sites, dinâmicos e estáticos!",
      text: "Dê vida à sua presença online com nossa gama completa de serviços de criação de sites! Seja para projetos dinâmicos ou estáticos, utilizamos um arsenal de tecnologias de ponta - HTML5, CSS3, JavaScript, PHP, ReactJs e Wordpress - para construir sua plataforma digital perfeita. Nosso compromisso vai além do desenvolvimento, mantendo um contato constante para entender suas necessidades e garantir que cada detalhe seja perfeitamente capturado. Estamos prontos para transformar sua visão em um site impressionante e funcional!",
      technologies: [FaHtml5, FaCss3Alt, FaJs, FaPhp, FaReact, FaWordpress],
    },
    {
      title: "Criação de <span>Aplicativos Mobile</span>",
      text_resum: "Oferecemos serviços de criação de aplicativos para android!",
      text: "Desbrave novos horizontes no mundo mobile com nossa especialidade em criação de aplicativos para Android! Utilizando a potência e versatilidade do React Native, moldamos suas ideias em experiências interativas e funcionais. Nosso compromisso vai além do desenvolvimento técnico, priorizamos uma comunicação constante para entender a essência do seu projeto, garantindo que cada funcionalidade seja cuidadosamente integrada. Estamos prontos para transformar sua visão em um aplicativo cativante e impactante!",
      technologies: [FaReact],
    },
    {
      title: "<span>Assitência</span> e Monitoramento",
      text_resum: "Oferecemos assistência ao seu projeto desenvolvido conosco e acompanhe de perto o desenvolvimento!",
      text: "Mantenha-se tranquilo enquanto cuidamos do seu projeto! Além de oferecer assistência contínua, nosso compromisso inclui um acompanhamento próximo do desenvolvimento. Utilizamos ferramentas de ponta, como Github e Trello, para garantir total transparência e controle em todas as etapas. Nosso foco é assegurar não apenas a qualidade técnica, mas também uma comunicação constante para que você se sinta parte integral do processo. Conte conosco para acompanhar de perto e elevar seu projeto ao próximo nível!",
      technologies: [FaGithub, FaTrello],
    },
  ],
  text_used: "TECNOLOGIAS USADAS:",
  button_text: "Veja sobre as tecnologias",
};