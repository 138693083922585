// LIBs
import { useState } from "react";
import { toast } from "react-toastify";

// COMPONENTs
import { Modal } from "../../components/Modal";

// CONATINERs
import { HomeBanner } from "../../containers/home/banner";
import { HomeExplorer } from "../../containers/home/explorer";
import { HomeProject } from "../../containers/home/project";
import { HomeServices } from "../../containers/home/services";
import { HomeTechnologies } from "../../containers/home/technologies";
import { HomeContact } from "../../containers/home/contact";
import { HomeSendForm } from "../../containers/home/sendForm";
import { HomeTool } from "../../containers/home/tool";
import { HomeToolButton } from "../../containers/home/tool-button";

// CONSTANTs
import { useTranslate } from "../../core/constants/useTranslate";

// UTILs
import { sendContact } from "../../core/utils/Api";

// STYLEs
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export function HomeView() {  
  const { t } = useTranslate();

  const [ categoryProjects, setCategoryProjects ] = useState<number | string>(0);
  const [ categoryTechnologies, setCategoryTechnologies ] = useState<number | string>(0);
  const [ selectProject, setSelectProject ] = useState<number | null>(null);
  const [ selectService, setSelectService ] = useState<number>(0);
  const [ categoryGroupTechnologies, setCategoryGroupTechnologies ] = useState<number>(0);
  const [ shownSettings, setShownSettings ] = useState<boolean>(false);
  const [ sendForm, setSendForm ] = useState<boolean>(false);

  const sendFormContact = async (name: string, email: string, phone: string, subject: string, message: string) => {
    const req = await sendContact({
      name,
      email,
      phone,
      subject,
      message,
    });

    if (req.data) {
      setSendForm(true);
      return true;
    } else {      
      toast.error(t("home").contact?.form_request[req.error.message]);
      return false;
    }
  }

  return (
    <main className="HomeWrapper">
      <HomeBanner />
      <HomeExplorer 
        categoryProjects={categoryProjects}
        categoryTechnologies={categoryTechnologies}
        onChangeCategoryProjects={(value: string) => setCategoryProjects(value)}
        onChangeCategoryTechnologies={(value: string) => setCategoryTechnologies(value)}
        onClickProject={(index: number) => setSelectProject(index)}
      />
      <HomeServices 
        serviceIndex={selectService} 
        onChangeSerivce={setSelectService}
      />
      <HomeTechnologies 
        category={categoryGroupTechnologies}
        onChangeCategory={setCategoryGroupTechnologies}
      />
      <HomeContact 
        onSend={sendFormContact}
      />
      <Modal 
        show={selectProject !== null}
        onHide={() => {
          setSelectProject(null);
        }}
      >
        <HomeProject indexOfProject={selectProject} />
      </Modal>
      <Modal
        show={sendForm}
        onHide={() => {
          setSendForm(false);
        }}
      >
        <HomeSendForm />
      </Modal>
      <Modal
        show={shownSettings}
        width={450}
        onHide={() => {
          setShownSettings(false);
        }}
      >
        <HomeTool />
      </Modal>
      <HomeToolButton 
        onClick={() => {
          setShownSettings(true);
        }}
      />
    </main>
  );
}
