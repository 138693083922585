export const requestDT_ptBR = {
  "Falta paramêtros na requisição!": "Falta paramêtros na requisição!",
  "Credenciais inválidas!": "Credenciais inválidas!",
  "Você não tem permissão!": "Você não tem permissão!",
  "Não há nenhum usuário!": "Não há nenhum usuário!",
  "Nenhum usuário encontrado!": "Nenhum usuário encontrado!",
  "ID da hierarquia/status não reconhecidos!": "ID da hierarquia/status não reconhecidos!",
  "Já há um usuário com esse email!": "Já há um usuário com esse email!",
  "Não há nenhum formulário de contato!": "Não há nenhum formulário de contato!",
  "Formulário de contato não encontrado!": "Formulário de contato não encontrado!",
  "Faça alterações dos campos!": "Faça alterações dos campos!",
  "Extensão da imagem não é aceita!": "Extensão da imagem não é aceita!",
};
