// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// TYPEs
import { IContactsTypes } from "../../../types/contactsTypes";

// STYLEs
import { Data } from "./styles";

interface IContactsDataProps {
  data: IContactsTypes | null;
}

export function ContactsData({ data }: IContactsDataProps) {
  const { t } = useTranslate();

  return (
    <Data id="view-contacts">
      { data &&
        <div className="Data">
          <div className="Data__header">
            <h2>{t("contacts").form?.subtitle.replace("contactId", data.id)}</h2>
          </div>

          <div className="Data__details">
            <div>
              <p>{data.name}</p>
              <span>{data.email}</span>
            </div>

            <div>
              <p>{data.phone}</p>
            </div>
          </div>

          <div className="Data__info">
            <h2>{data.subject}</h2>
            <p>{data.message}</p>
          </div>
        </div>
      }
    </Data>
  );
}
