// LIBs
import { toast } from "react-toastify";

// CONTAINERs
import { MyProfileForm } from "../../containers/my-profile/form";

// CONFIG
import { useToolkitSelector, useToolkitDispatch } from "../../core/config/store";

// CONTANTs
import { useTranslate } from "../../core/constants/useTranslate";

// REDUCERs
import { getUser } from "../../core/reducers/user-reducer/selector";
import { setUser } from "../../core/reducers/user-reducer";

// UTILs
import { getMe, updateMe } from "../../core/utils/Api";

export function MyProfileView() {
  const user = useToolkitSelector(getUser);
  const { t } = useTranslate();

  const dispatch = useToolkitDispatch();

  const sendMe = async (name: string, email: string, password: string, photo: null | string | any) => {
    const params: any = {
      name,
      email,
      password,
    };

    if (photo && typeof photo !== "string") params.photo = photo;

    const req = await updateMe(params, user);

    if (req.error) {
      if (req.error.message === "Falta paramêtros na requisição!") {
        toast.warn(t("common").request["Faça alterações dos campos!"]);
      } else {
        toast.error(t("common").request[req.error.message]);
      }
    } else {
      const reqMe = await getMe();
      dispatch(setUser(reqMe.data.user));
    }
  }

  return (
    <main className="MyProfileWrapper">
      <MyProfileForm 
        onSend={sendMe}
      />
    </main>
  );
}
