// LIBs
import { ReactNode } from "react";

// STYLEs
import { ContentDash } from "./styles";

interface IContentDashLayoutProps {
  children: ReactNode;
}

export function ContentDashLayout({ children }: IContentDashLayoutProps) {
  return (
    <ContentDash>
      <div className="ContentDash">
        {children}
      </div>
    </ContentDash>
  );
}
