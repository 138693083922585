export const sizes = {
  // In PX
  ftSII: 8,
  ftSI: 10,
  ftS: 12,
  ftR: 14,
  ft: 16,
  ftM: 18,
  ftB: 20,
  ftBI: 22,
  ftBII: 24,
  ftBIII: 26,
  ftX: 30,
  ftXI: 34,
  ftXII: 38,
  ftXIII: 42,

  // In VW Mobile
  ftSIIm: 1.7,
  ftSIm: 2.2,
  ftSm: 2.6,
  ftRm: 3.1,
  ftm: 3.5,
  ftMm: 4,
  ftBm: 4.4,
  ftBIm: 4.8,
  ftBIIm: 5.3,
  ftBIIIm: 5.7,
  ftXm: 6.6,
  ftXIm: 7.5,
  ftXIIm: 8.4,
  ftXIIIm: 9.3,
};