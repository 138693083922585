export const inputsDT_ptBR = {
  inputs: {
    photo: {
      label: "Imagem de Perfil:"
    },
    name: {
      label: "Nome Completo:",
      placeholder: "Digite o nome completo do usuário...",
    },
    email: {
      label: "E-mail:",
      placeholder: "Digite o e-mail do usuário...",
    },
    password: {
      label: "Senha:",
      placeholder: "Digite a senha do usuário...",
    },
    hierarchy: {
      label: "Hierarquia:",
    },
    status: {
      label: "Status:",
    },
    language: {
      label: "Escolha seu Idioma de Preferência:",
      data: [
        {
          id: "pt-BR",
          name: "Português",
        },
        {
          id: "en-US",
          name: "Inglês",
        },
      ],
    },
    mode: {
      label: "Ativar modo escuro?",
    },
  },
  settings_title: "Configurações de Exibição",
  btn_settings: "Configurações",
  inputs_invalid: "Preencha todos os campos corretamente!",
  email_invalid: "E-mail inválido!",
  phone_invalid: "Telefone inválido!",
  text_invalid: "O campo precisa ter mais de qtdCarac caracteres!",
  password_invalid: {
    carac: "Precisa ter mais de 7 caracteres!",
    uppercase: "Precisa ter ao menos uma letra maiúscula!",
    number: "Precisa ter ao menos um número!",
    special: "Precisa ter ao menos um caractere especial!",
  },
};