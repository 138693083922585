// LIBs
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

// COMPONENTs
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select";
import { Button } from "../../../components/Button";

// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// UTILs
import { getHierarchies, getStatusList } from "../../../core/utils/Api";
import { isText, isEmail, isPassword } from "../../../core/utils/validator";

// TYPEs
import { IUserTypes } from "../../../types/usersTypes";

// STYLEs
import { FormContainer } from "./styles";

interface IUsersSendFormProps {
  data: IUserTypes | null;
  onCancel: any;
  onSend: any;
}

let nameTimeout: any;
let emailTimeout: any;
let passwordTimeout: any;

export function UsersForm({ data, onCancel, onSend }: IUsersSendFormProps) {
  const { t } = useTranslate();

  const [ hierarchies, setHierarchies ] = useState<any[]>([]);
  const [ statusList, setStatusList ] = useState<any[]>([]);
  const [ load, setLoad ] = useState<boolean>(false);

  const [ name, setName ] = useState<string>("");
  const [ nameError, setNameError ] = useState<string>("");
  const [ email, setEmail ] = useState<string>("");
  const [ emailError, setEmailError ] = useState<string>("");
  const [ password, setPassword ] = useState<string>("");
  const [ passwordError, setPasswordError ] = useState<string>("");
  const [ hierarchy, setHierarchy ] = useState<string>("");
  const [ status, setStatus ] = useState<string>("");

  useEffect(() => {
    fetch();
  }, [])

  useEffect(() => {
    if (data) {
      setName(data.name);
      setEmail(data.email);
      setHierarchy(data.config_hierarchy.id);
      setStatus(data.config_status.id);
      clearError();
    } else {
      clear();
    }
  }, [data])

  const fetch = async () => {
    const req = await Promise.all([
      getHierarchies(),
      getStatusList(),
    ]);

    if (req[0].data) {
      setHierarchies(req[0].data.hierarchies);
      if (req[0].data.hierarchies.length > 0) setHierarchy(req[0].data.hierarchies[0].id);
    } else {
      toast.error(req[0].error.message);
    }

    if (req[1].data) {
      setStatusList(req[1].data.status_list);
      if (req[1].data.status_list.length > 0) setStatus(req[1].data.status_list[0].id);
    } else {
      toast.error(req[1].error.message);
    }
  }

  const validateForm = () => {
    const newNameError = isText(name, (qtdCarac: number) => {
      return t("common").inputs?.text_invalid.replace("qtdCarac", qtdCarac);
    });
    const newEmailError = isEmail(email, t("common").inputs?.email_invalid);
    const newPasswordError = isPassword(password, t("common").inputs?.password_invalid);

    setNameError(newNameError);
    setEmailError(newEmailError);
    setPasswordError(newPasswordError);

    return !newNameError && !newEmailError && !newPasswordError;
  }

  const send = async () => {
    setLoad(true);

    if (!data && validateForm()) {
      const result = await onSend(name, email, password, hierarchy, status);
      if (result) clear();
    } else if (data) {
      let valid = true;
      if (name.trim() && email.trim() && password.trim()) valid = validateForm();
      if (valid) {
        const result = await onSend(name, email, password, hierarchy, status);
        if (result) clear();
      }
    }

    setTimeout(() => setLoad(false), 1000);
  }

  const clear = () => {
    setName("");
    setEmail("");
    setPassword("");
    if (hierarchies.length > 0) setHierarchy(hierarchies[0].id);
    if (statusList.length > 0) setStatus(statusList[0].id);
  }

  const clearError = () => {
    setNameError("");
    setEmailError("");
    setPasswordError("");
  }

  return (
    <FormContainer load={load ? 1 : 0} id="send-form-users">
      <div className="FormContainer">
        <div className="FormContainer__header">
          <h2>
            { data
              ? t("users").content?.subtitle_edit.replace("userId", data.id)
              : t("users").content?.subtitle
            }
          </h2>
        </div>

        <div className="FormContainer__inputs">
          <Input 
            label={t("common").inputs?.inputs.name.label}
            value={name}
            onChange={(event: any) => {
              setName(event.target.value);
              clearTimeout(nameTimeout);
              nameTimeout = setTimeout(() => {
                setNameError(isText(event.target.value, (qtdCarac: number) => {
                  return t("common").inputs?.text_invalid.replace("qtdCarac", qtdCarac);
                }));
              }, 1000);
            }}
            error={nameError}
            placeholder={t("common").inputs?.inputs.name.placeholder}
          />
          <Input 
            label={t("common").inputs?.inputs.email.label}
            value={email}
            onChange={(event: any) => {
              setEmail(event.target.value);
              clearTimeout(emailTimeout);
              emailTimeout = setTimeout(() => {
                setEmailError(isEmail(event.target.value, t("common").inputs?.email_invalid));
              }, 1000);
            }}
            error={emailError}
            placeholder={t("common").inputs?.inputs.email.placeholder}
          />
          <Input 
            type="password"
            label={t("common").inputs?.inputs.password.label}
            value={password}
            onChange={(event: any) => {
              setPassword(event.target.value);
              clearTimeout(passwordTimeout);
              passwordTimeout = setTimeout(() => {
                setPasswordError(isPassword(event.target.value, t("common").inputs?.password_invalid));
              }, 1000);
            }}
            error={passwordError}
            placeholder={t("common").inputs?.inputs.password.placeholder}
          />
          <Select 
            data={hierarchies.map((hierarchy) => ({
              name: hierarchy.name,
              id: hierarchy.id,
            }))}
            label={t("common").inputs?.inputs.hierarchy.label}
            value={hierarchy}
            onChange={(event: any) => {
              setHierarchy(event.target.value);
            }}
          />
          <Select 
            data={statusList}
            label={t("common").inputs?.inputs.status.label}
            value={status}
            onChange={(event: any) => {
              setStatus(event.target.value);
            }}
          />
        </div>

        <div className="FormContainer__buttons">
          <Button 
            title={data ? t("users").content?.btn_edit : t("users").content?.btn_create}
            load={load}
            onClick={() => {
              send();
            }}
          />
          { data &&
            <Button 
              title={t("users").content?.btn_cancel}
              onClick={() => {
                onCancel();
                clearError();
              }}
            />
          }
        </div>
      </div>
    </FormContainer>
  );
}
