// ICONs
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaWhatsapp, FaThreads } from "react-icons/fa6";

const futureDate = new Date();
futureDate.setFullYear(new Date().getFullYear() + 10);

export const footerDT_enUS = {
  title: "Lêvy P. Alves",
  networks: [
    {
      Icon: FaFacebookF,
      link: "https://www.facebook.com"
    },
    {
      Icon: FaLinkedinIn,
      link: "https://www.linkedin.com"
    },
    {
      Icon: FaInstagram,
      link: "https://www.instagram.com"
    },
    {
      Icon: FaWhatsapp,
      link: "https://wa.me/5547991966719"
    },
    {
      Icon: FaThreads,
      link: "https://www.threads.net"
    },
  ],
  text: "Power your online presence with a modern, high-performance website - increase your influence to new heights!",
  text_copywriter: `©${(new Date()).getFullYear()}-${futureDate.getFullYear()} Lêvy P. Alves - All rights reserved`,
};