// STYLEs
import { CardTechnologiesContainer } from "./styles";

export interface ICardTechnologiesProps {
  Icon: any;
  timeUsed: string;
  name: string;
  description: string;
}

export function CardTechnologies({ Icon, timeUsed, name, description }: ICardTechnologiesProps) {
  return (
    <CardTechnologiesContainer>
      <div className="CardTechnologiesContainer">
        <div className="CardTechnologiesContainer__info">
          <div className="CardTechnologiesInfo">
            <div className="CardTechnologiesInfo__icon">
              <span>
                <Icon />
              </span>
            </div>

            <div className="CardTechnologiesInfo__details">
              <p>{name}</p>
              <span>{timeUsed}</span>
            </div>
          </div>
        </div>

        <div className="CardTechnologiesContainer__description">
          <p>{description}</p>
        </div>
      </div>
    </CardTechnologiesContainer>
  );
}
