// COMPONENTs
import { Menu } from "../../components/Menu";

// CONFIG
import { useToolkitSelector } from "../../core/config/store";

// CONSTANTs
import { useTranslate } from "../../core/constants/useTranslate";

// REDUCERs
import { getActiveLink } from "../../core/reducers/app-reducer/selector";

// STYLEs
import { Footer } from "./styles";

export function FooterLayout() {
  const { t } = useTranslate();
  
  const activeLink = useToolkitSelector(getActiveLink);

  return (
    <Footer>
      <div className="Footer">
        <div className="Footer__title">
          <h4>{t("home").footer?.title}</h4>
        </div>

        <div className="Footer__menu">
          <Menu 
            data={t("home").menu?.list}
            activeLink={activeLink}
            shown
          />
        </div>

        <div className="Footer__social">
          {
            t("home").footer?.networks.map((network: any, index: number) => {
              const Icon = network.Icon;
              return (
                <a 
                  href={network.link} 
                  target="_blank" 
                  key={index}
                >
                  <Icon />
                </a>
              )
            })
          }
        </div>

        <div className="Footer__text">
          <p>{t("home").footer?.text}</p>
        </div>
      </div>
      <div className="FooterCopywriter">
        <p>{t("home").footer?.text_copywriter}</p>
      </div>
    </Footer>
  );
}
