// LIBs
import { ReactNode } from "react";

// STYLEs
import { ModalContainer } from "./styles";

export interface IModalProps {
  show: boolean;
  width?: number;
  children?: ReactNode;
  onHide: any;
}

export function Modal({ show, width, children, onHide }: IModalProps) {
  return (
    <ModalContainer 
      className="Modal" 
      show={show ? 1 : 0} 
      onClick={(event: any) => {
        if (event.target.classList.contains("Modal")) {
          onHide();
        }
      }}
    >
      <div 
        className="ModalContainer"
        style={{
          maxWidth: width ? width : "auto",
          width: width ? width : "auto",
        }}
      >
        {children}
      </div>
    </ModalContainer>
  );
}
