// ICONs
import { FaFigma, FaHtml5, FaCss3, FaJs, FaPhp, FaReact, FaWordpress, FaGithub, FaTrello } from "react-icons/fa6";

export const technologiesDT_ptBR = {
  title: "Tecnologias",
  text: "Descubra o poder por trás das incríveis aplicações que impulsionam nossos projetos: uma combinação habilidosa de tecnologias avançadas que moldam experiências excepcionais. 🚀",
  filter_text: "Filtre às tecnologias:",
  btn_text_more: "Ver mais",
  btn_text_less: "Ver menos",
  btn_text_budget: "Fazer Orçamento",
  categories: [
    "Todos",
    "Criação de Websites",
    "Criação de App Mobile",
    "Ferramentas"
  ],
  list: [
    {
      icon: FaFigma,
      time_used: "Menos de 1 Ano",
      name: "Figma",
      description: "Uma ferramenta de design de interface do usuário baseada na web, usada para criar protótipos, designs de aplicativos e sites. Figma permite colaboração em tempo real e é popular entre designers e equipes de desenvolvimento.",
      categories: ["Todos", "Ferramentas"],
    },
    {
      icon: FaHtml5,
      time_used: "3 - 5 Anos",
      name: "HTML5",
      description: "A versão mais recente da linguagem de marcação utilizada para estruturar e apresentar conteúdo na web. Oferece recursos mais avançados, como áudio, vídeo, gráficos vetoriais e APIs que melhoram a experiência do usuário.",
      categories: ["Todos", "Criação de Websites"],
    },
    {
      icon: FaCss3,
      time_used: "3 - 5 Anos",
      name: "CSS3",
      description: "É a linguagem usada para estilizar elementos HTML, permitindo o controle detalhado do layout, cores, fontes e animações em páginas da web.",
      categories: ["Todos", "Criação de Websites"],
    },
    {
      icon: FaJs,
      time_used: "3 - 5 Anos",
      name: "JavaScript",
      description: "Uma linguagem de programação fundamental para o desenvolvimento web. É utilizada para criar interações dinâmicas em páginas, como validação de formulários, animações, jogos e muito mais.",
      categories: ["Todos", "Criação de Websites"],
    },
    {
      icon: FaPhp,
      time_used: "2 - 4 Anos",
      name: "PHP",
      description: "Uma linguagem de programação do lado do servidor usada principalmente para desenvolvimento web. É usada para criar aplicativos web dinâmicos e interagir com bancos de dados.",
      categories: ["Todos", "Criação de Websites"],
    },
    {
      icon: FaReact,
      time_used: "2 - 4 Anos",
      name: "React",
      description: "Uma biblioteca JavaScript desenvolvida pelo Facebook, utilizada para criar interfaces de usuário interativas e componentizadas. É conhecida por sua eficiência e popularidade no desenvolvimento de aplicativos web.",
      categories: ["Todos", "Criação de Websites"],
    },
    {
      icon: FaWordpress,
      time_used: "Menos de 1 ano",
      name: "Wordpress",
      description: "Um sistema de gerenciamento de conteúdo (CMS) amplamente utilizado para criar websites e blogs. É altamente personalizável e possui uma grande variedade de plugins e temas para estender suas funcionalidades.",
      categories: ["Todos", "Criação de Websites"],
    },
    {
      icon: FaReact,
      time_used: "Menos de 1 ano",
      name: "React Native",
      description: "Um framework JavaScript usado para desenvolver aplicativos móveis para iOS e Android. Ele permite o desenvolvimento de aplicativos móveis usando React, compartilhando grande parte do código entre as plataformas.",
      categories: ["Todos", "Criação de App Mobile"],
    },
    {
      icon: FaGithub,
      time_used: "3 Anos",
      name: "GitHub",
      description: "Uma plataforma de hospedagem de código-fonte e colaboração, amplamente utilizada por desenvolvedores para controle de versão, colaboração em projetos e compartilhamento de código.",
      categories: ["Todos", "Ferramentas"],
    },
    {
      icon: FaTrello,
      time_used: "Menos de 1 ano",
      name: "Trello",
      description: "Uma ferramenta de gerenciamento de projetos baseada em quadros, que permite organizar tarefas em listas e cartões, sendo muito útil para acompanhar o progresso das atividades em equipe.",
      categories: ["Todos", "Ferramentas"],
    },
  ],
};
