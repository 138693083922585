export const contentDT_ptBR = {
  title: "Conecte-se ao <span>JSNinja Platform</span>",
  text: "CONECTE-SE AGORA E ACOMPANHE O DESENVOLVIMENTO DE SEUS PROJETOS CONOSCO!",
  email_input: {
    label: "E-mail:",
    placeholder: "Digite seu e-mail...",
  },
  password_input: {
    label: "Senha:",
    placeholder: "Digite seu senha...",
  },
  btn_label: "Fazer Login",
  inputs_invalid: "Preencha os campos corretamente!",
  form_request: {
    "Credenciais inválidas!": "Credenciais inválidas!",
  },
};