// LIBs
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jsCookie from "js-cookie";

export type LanguageTypes = "pt-BR" | "en-US";
export type ModeTypes = "light" | "dark";

export interface IScreenSizesTypes {
  width: number;
  height: number;
};

export interface IInitialStateAppTypes {
  language: LanguageTypes;
  mode: ModeTypes;
  scrollTop: number;
  activeLink: string;
  screen: IScreenSizesTypes;
};

const initialState: IInitialStateAppTypes = {
  language: "pt-BR",
  mode: "dark",
  scrollTop: 0,
  activeLink: "banner",
  screen: {
    width: 0,
    height: 0,
  },
};

export const languageName = "@jsninja::language";
export const modeName = "@jsninja::mode";

let language: LanguageTypes = jsCookie.get(languageName) as LanguageTypes || "pt-BR";
let mode: ModeTypes = jsCookie.get(modeName) as ModeTypes || "dark";

if (language) {
  initialState.language = language;
}

if (mode) {
  initialState.mode = mode;
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<LanguageTypes>) => {
      state.language = action.payload;
      jsCookie.set(languageName, action.payload);
    },
    setMode: (state, action: PayloadAction<ModeTypes>) => {
      state.mode = action.payload;
      jsCookie.set(modeName, action.payload);
    },
    setScrollTop: (state, action: PayloadAction<number>) => {
      state.scrollTop = action.payload;
    },
    setActiveLink: (state, action: PayloadAction<string>) =>  {
      state.activeLink = action.payload;
    },
    setScreen: (state, action: PayloadAction<IScreenSizesTypes>) => {
      state.screen = action.payload;
    },
  },
});

export const { setLanguage, setMode, setScrollTop, setActiveLink, setScreen } = appSlice.actions;
export default appSlice.reducer;
