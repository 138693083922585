// LIBs
import { Link } from "react-scroll";

// COMPONENTs
import { Table, ITableColumnsTypes } from "../../../components/Table";

// CONFIG
import { useToolkitSelector } from "../../../core/config/store";

// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// REDUCERs
import { getUser } from "../../../core/reducers/user-reducer/selector";

// TYPEs
import { IUserMinTypes } from "../../../types/usersTypes";

// ICONs
import { FiUser, FiEdit, FiTrash } from "react-icons/fi";

// STYLEs
import { List } from "./styles";

interface IUsersListProps {
  data: IUserMinTypes[];
  onEdit: any;
  onDelete: any;
}

export function UsersList({ data, onEdit, onDelete }: IUsersListProps) {
  const user = useToolkitSelector(getUser);

  const { t } = useTranslate();

  const access = user ? JSON.parse(user.config_hierarchy.access) : null;

  const renderPhoto = (photo: string | null) => {
    if (photo) {
      return <img src={photo} className="ImageProfile" alt="" />
    } else {
      return (
        <span className="ImageProfile">
          <FiUser />
        </span>
      );
    }
  }

  const renderActions = (id: string) => {
    return (
      <div className="Links">
        { (access && access.users && access.users.includes("UPDATE")) &&
          <Link
            to="send-form-users"
            duration={400}
            smooth
            onClick={() => {
              onEdit(id);
            }}
          >
            <FiEdit />
          </Link>
        }
        { (access && access.users && access.users.includes("DELETE")) &&
          <a 
            href="#"
            onClick={(event: any) => {
              event.preventDefault();
              onDelete(id);
            }}
          >
            <FiTrash />
          </a>
        }
        { (
            !access || 
            !access.users || 
            (
              !access.users.includes("UPDATE") &&
              !access.users.includes("DELETE")
            )
          ) &&
          <p>{t("users").content?.table.none_actions}</p>
        }
      </div>
    );
  }

  const columns: ITableColumnsTypes[] = [
    {
      label: t("users").content?.table.photo,
      name: "photo",
      renderItem: renderPhoto,
    },
    {
      label: t("users").content?.table.name,
      name: "name",
    },
    {
      label: t("users").content?.table.email,
      name: "email",
    },
    {
      label: t("users").content?.table.actions,
      name: "id",
      renderItem: renderActions,
    }
  ];

  return (
    <List>
      <div className="List">
        <div className="List__header">
          <h1>{t("users").content?.title}</h1>
        </div>

        <div className="List__table">
          <Table 
            columns={columns}
            rows={data}
          />
        </div>
      </div>
    </List>
  );
}
