export const explorerDT_ptBR = {
  title: "Explore Meus Projetos",
  text: "Veja todos os projetos produzidos conosco, desde projetos pessoais até mesmo de outros clientes, veja a maravilha do desenvolvimento web e mobile, e seja você o próximo a construir a propagar o seu negócio online com seu próprio website ou app.",
  categories_projects_title: "Tipo de Projeto:",
  categories_projects: [
    "Todos",
    "One Page",
    "Landing Page",
    "Sistema",
    "Institucional",
    "E-commerce",
    "App",
  ],
  categories_technologies_title: "Tecnologia Usada:",
  categories_technologies: [
    "Todos",
    "Vanila",
    "PHP",
    "Wordpress",
    "ReactJS",
    "React Native",
    "NodeJS",
    'Laravel',
  ],
  projects: [
    {
      new: true,
      model: true,
      name: "Institucional Engenharia",
      about: "O projeto institucional engenharia focado na apresentação de uma empresa voltada a engenhari civil. Foi desenvolvido para um cliente, este é um projeto fullstack onde temos o frontend e o backend em um mesmo projeto, utilizei a estrutura MVC no PHP para construir essa aplicação.",
      cover: require("../../../../assets/images/projects/engenharia/engenharia_cover.png"),
      images: [
        require("../../../../assets/images/projects/engenharia/home.jpeg"),
        require("../../../../assets/images/projects/engenharia/about.jpeg"),
        require("../../../../assets/images/projects/engenharia/projects.jpeg"),
        require("../../../../assets/images/projects/engenharia/portfolio.jpeg"),
        require("../../../../assets/images/projects/engenharia/dashboard.jpeg"),
      ],
      tags: ["Todos", "Institucional"],
      technologies: ["Todos", "PHP"],
      link_github: "https://github.com/LevyAlves1532/engenharia",
      reference: null,
      link_download: null,
      link_preview: null,
      link_figma: null,
    },
    {
      new: false,
      model: true,
      name: "Ticket Events",
      about: "Desenvolvi um simples gerenciador de eventos que permite total controle sobre os compradores, incluindo funcionalidades completas de CRUD, migrações e seeders, com validações robustas implementadas tanto no frontend quanto no backend.",
      cover: require("../../../../assets/images/projects/ticket_events/ticket_events_cover.png"),
      images: [
        require("../../../../assets/images/projects/ticket_events/home.jpeg"),
        require("../../../../assets/images/projects/ticket_events/events.jpeg"),
        require("../../../../assets/images/projects/ticket_events/edit_event.jpeg"),
        require("../../../../assets/images/projects/ticket_events/add_buyer.jpeg"),
        require("../../../../assets/images/projects/ticket_events/add_buyer_in_event.jpeg"),
      ],
      tags: ["Todos", "Sistema"],
      technologies: ["Todos", "Laravel", "PHP"],
      link_github: "https://github.com/LevyAlves1532/ticket-events/tree/master",
      reference: null,
      link_download: null,
      link_preview: null,
      link_figma: null,
    },
    {
      new: false,
      model: true,
      name: "SGE",
      about: "Este projeto foi construido como parte de um aprendizado, fiz um gerenciador de estoques com base em um gerenciado de contatos que fiz em um curso, e trouxe novas funcionalidades, como upload de imagens, filtro por categorias e muito mais.",
      cover: require("../../../../assets/images/projects/sge/sge_cover.png"),
      images: [
        require("../../../../assets/images/projects/sge/sign-in.jpeg"),
        require("../../../../assets/images/projects/sge/home.jpeg"),
        require("../../../../assets/images/projects/sge/categories.jpeg"),
        require("../../../../assets/images/projects/sge/categories-add.jpeg"),
        require("../../../../assets/images/projects/sge/products-edit.jpeg"),
      ],
      tags: ["Todos", "Sistema"],
      technologies: ["Todos", "NodeJS"],
      link_github: "https://github.com/LevyAlves1532/sistema-de-estoque",
      reference: null,
      link_download: null,
      link_preview: null,
      link_figma: null,
    },
    {
      new: false,
      model: true,
      name: "Barber Web Template",
      about: "O Barber Web Template é um projeto focado na apresentação de uma barbearia. Desenvolvido como parte de um aprendizado pessoal, concentrando-se principalmente na interface e na experiência do usuário em uma One Page profissional.",
      cover: require("../../../../assets/images/projects/barber_web/barber_web_cover.png"),
      images: [
        require("../../../../assets/images/projects/barber_web/banner.jpeg"),
        require("../../../../assets/images/projects/barber_web/about.jpeg"),
        require("../../../../assets/images/projects/barber_web/services.jpeg"),
        require("../../../../assets/images/projects/barber_web/portfolio.jpeg"),
        require("../../../../assets/images/projects/barber_web/contact.jpeg"),
      ],
      tags: ["Todos", "One Page"],
      technologies: ["Todos", "ReactJS"],
      link_github: "https://github.com/LevyAlves1532/barber-web-template",
      reference: null,
      link_download: null,
      link_preview: "https://jsninjajourney.com.br/sites/barber-web-template/",
      link_figma: "https://www.figma.com/file/bQ8oI26ml0jaLbHkUkEhCA/Barber-Web-Design?type=design&node-id=0%3A1&mode=design&t=0necF8FOt6yOu8aw-1",
    },
    {
      new: false,
      model: true,
      name: "Catalog Pizzas",
      about: "Catalog Pizzas é uma plataforma interativa que permite aos amantes de pizza personalizarem suas delícias e encaminhar seus pedidos diretamente para o WhatsApp da pizzaria.",
      cover: require("../../../../assets/images/projects/catalog_pizzas/catalog_pizza_cover.png"),
      images: [
        require("../../../../assets/images/projects/catalog_pizzas/home.jpeg"),
        require("../../../../assets/images/projects/catalog_pizzas/ingredients.jpeg"),
        require("../../../../assets/images/projects/catalog_pizzas/drinks.jpeg"),
        require("../../../../assets/images/projects/catalog_pizzas/cart_one.jpeg"),
        require("../../../../assets/images/projects/catalog_pizzas/cart_second.jpeg"),
      ],
      tags: ["Todos", "Sistema"],
      technologies: ["Todos", "ReactJS"],
      link_github: "https://github.com/LevyAlves1532/catalog-pizza",
      reference: null,
      link_download: null,
      link_preview: null,
    },
    {
      new: false,
      model: true,
      name: "Pizza Shop",
      about: "O Pizza Shop é um projeto de design de aplicativo focado na compra de pizzas, priorizando uma experiência de usuário intuitiva para pizzarias. Parte de aprendizado pessoal.",
      cover: require("../../../../assets/images/projects/pizza_shop/pizza_shop_cover.png"),
      images: [
        require("../../../../assets/images/projects/pizza_shop/header_home.jpeg"),
        require("../../../../assets/images/projects/pizza_shop/info_pizza.jpeg"),
        require("../../../../assets/images/projects/pizza_shop/buy_cart.jpeg"),
        require("../../../../assets/images/projects/pizza_shop/header_favorites.jpeg"),
        require("../../../../assets/images/projects/pizza_shop/header_historic.jpeg"),
      ],
      tags: ["Todos", "App"],
      technologies: ["Todos", "React Native"],
      link_github: "https://github.com/LevyAlves1532/pizza_shop_rn",
      reference: null,
      link_download: null,
      link_preview: null,
    },
  ],
  card_subtitle: "Novo Projeto",
  found_projects: "Não há nenhum projeto com essas especificações!",
  button_contact: "Nossos Serviços",
};