// LIBs
import { ToastContainer } from "react-toastify";

// CONFIG
import { useToolkitSelector } from "../../core/config/store";

// REDUCERs
import { getMode } from "../../core/reducers/app-reducer/selector";

// STYLEs
import { Toast as ToastStyled } from "./styles";

export function ToastLayout() {
  const mode = useToolkitSelector(getMode);

  return (
    <ToastStyled>
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        rtl={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        theme={mode}
      />
    </ToastStyled>
  );
}
