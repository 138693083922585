// CONSTANTs
import { useTranslate } from "../../core/constants/useTranslate";

// STYLEs
import { CardExplorerContainer } from "./styles";

export interface ICardExplorerProps {
  image: string;
  newProject: boolean;
  name: string;
  onClick: any;
}

export function CardExplorer({ image, name, newProject, onClick }: ICardExplorerProps) {
  const { t } = useTranslate();

  return (
    <CardExplorerContainer onClick={onClick}>
      <div className="CardExplorerContainer">
        <div className="CardExplorerContainer__image">
          <img src={image} alt="" />
        </div>

        <div className="CardExplorerContainer__info">
          <span>{newProject && t("home").explorer?.card_subtitle}</span>
          <p>{name}</p>
        </div>
      </div>
    </CardExplorerContainer>
  );
}
