// LIBs
import styled from "styled-components";

export const MenuContainer = styled.ul<{ show: number }>`
  display: flex;
  align-items: center;
  gap: 20px;

  @media ${props => props.theme.screens.tb} {
    margin-top: -20px;
    transition: all ease 0.5s;

    flex-direction: column;
    gap: 20px;

    ${props => props.show && `
      margin-top: 0;
    `}
  }

  li {
    a {
      padding: 10px 20px;
      font-size: ${props => props.theme.sizes.ft}px;
      text-transform: uppercase;
      color: ${props => props.theme.theme.mode.font};
      font-weight: 500;
      cursor: pointer;
      transition: all ease 0.5s;

      display: flex;
      flex-direction: column;
      align-items: center;

      position: relative;

      @media ${props => props.theme.screens.tb} {
        padding: 20px 40px;
        font-size: ${props => props.theme.sizes.ftB}px;
        color: ${props => props.theme.theme.mode.font};
        letter-spacing: 1px;
      }

      @media ${props => props.theme.screens.mbB} {
        padding: 4.4vw 8.8vw;
        font-size: ${props => props.theme.sizes.ftBm}vw;
      }

      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: ${props => props.theme.theme.green};
        border-radius: 5px;
        transition: all ease 0.5s;
        opacity: 0;

        display: block;

        position: absolute;
        top: calc(100% - 10px);

        @media ${props => props.theme.screens.mbB} {
          margin-top: 1.1vw;
          width: 2.2vw;
          height: 2.2vw;
        }
      }

      &:hover {
        color: ${props => props.theme.theme.green};
      }
    }

    &.active-linkMenu {
      a {
        color: ${props => props.theme.theme.green};

        display: flex;
        flex-direction: column;

        &::after {
          margin-top: 5px;
          opacity: 1;
        }
      }
    }
  }
`;
