// ICONs
import { FiGrid, FiUsers, FiHeadphones } from "react-icons/fi";

export const contentDT_enUS = {
  menu: [
    {
      Icon: FiGrid,
      to: "",
      label: "Home",
      is_access: "home",
    },
    {
      Icon: FiUsers,
      to: "/users",
      label: "Users",
      is_access: "users",
    },
    {
      Icon: FiHeadphones,
      to: "/contacts",
      label: "Contacts",
      is_access: "contacts",
    },
  ],
  menu_settings: [
    {
      to: "/my-profile",
      label: "My Profile",
    },
    {
      to: "/settings",
      label: "Settings",
    },
    {
      label: "Exit",
      onClick: (callback: any) => callback("exit"),
    },
  ],
  mode_label: "Mode Dark:",
  toolbox: [
    {
      label: "My Profile",
      to: "/my-profile",
    },
    {
      label: "Settings",
      to: "/settings",
    },
    {
      label: "Exit",
      onClick: (callback: any) => callback("exit"),
    },
  ],
};
