// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// ICONs
import { FiCheck } from "react-icons/fi";

// STYLEs
import { SendFormContainer } from "./styles";

export function HomeSendForm() {
  const { t } = useTranslate();

  return (
    <SendFormContainer>
      <div className="SendFormContainer">
        <div className="SendFormContainer__icon">
          <FiCheck />
        </div>

        <div className="SendFormContainer__title">
          <p>{t("home").contact?.modal_title}</p>
        </div>

        <div className="SendFormContainer__text">
          <p>{t("home").contact?.modal_text}</p>
        </div>
      </div>
    </SendFormContainer>
  );
}
