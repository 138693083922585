// LIBs
import { useState, useEffect } from "react";
import { Link } from "react-scroll";

// COMPONENTs
import { Menu } from "../../components/Menu";

// CONFIG
import { useToolkitSelector, useToolkitDispatch } from "../../core/config/store";

// CONSTANTs
import { useTranslate } from "../../core/constants/useTranslate";

// REDUCERs
import { getScrollTop, getActiveLink } from "../../core/reducers/app-reducer/selector";
import { setActiveLink } from "../../core/reducers/app-reducer";

// STYLEs
import { Header } from "./styles";

export function HeaderLayout() {
  const { t } = useTranslate();
  const dispatch = useToolkitDispatch();

  const scrollTop = useToolkitSelector(getScrollTop);
  const activeLink = useToolkitSelector(getActiveLink);

  const [ shownBG, setShownBG ] = useState<boolean>(false);
  const [ shownMenu, setShownMenu ] = useState<boolean>(false);

  useEffect(() => {
    updateLinkActive();
    updateShownBG();
  }, [scrollTop])

  const updateShownBG = () => {
    const section = document.querySelectorAll("section")[1];
    if (scrollTop >= section.offsetTop) {
      if (!shownBG) setShownBG(true);
    } else {
      if (shownBG) setShownBG(false);
    }
  }

  const updateLinkActive = () => {
    const sections = document.querySelectorAll("section");
    sections.forEach(el => {
      const middleBlock = (el.clientHeight / 4) * 1.
      const minSection = el.offsetTop - middleBlock;
      const maxSection = (el.offsetTop + el.clientHeight) - middleBlock;    
      if (scrollTop >= minSection && scrollTop < maxSection) {
        if (el.getAttribute("id") && activeLink !== el.getAttribute("id")) {
          dispatch(setActiveLink(el.getAttribute("id")!));
        }
      }
    });
  }

  return (
    <Header show={shownMenu ? 1 : 0} bgshow={shownBG ? 1 : 0}>
      <div className="HeaderContainer">
        <div className="HeaderContainer__logo">
          <Link 
            to="banner"
            duration={400}
            smooth
          >
            <img src={t("home").menu?.profile.image} alt="" />
          </Link>
        </div>

        <div className="HeaderContainer__nav">
          <nav>
            <Menu 
              data={t("home").menu?.list}
              activeLink={activeLink}
              shown={shownMenu}
              onChangeShown={setShownMenu}
            />
          </nav>
        </div>

        <div className="HeaderContainer__btn">
          <button 
            onClick={() => {
              setShownMenu(!shownMenu);
            }}
          >
            <div />
            <div />
            <div />
          </button>
        </div>
      </div>
    </Header>
  );
}
