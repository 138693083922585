export const theme = {
  dark: {
    bg: "#000",
    bgV2: "#444",
    bgGreen: "#052800",
    font: "#FFF",
    fontV2: "#CCC",
  },
  light: {
    bg: "#FFF",
    bgV2: "#CCC",
    bgGreen: "#A8DDA0",
    font: "#000",
    fontV2: "#444",
  },
};

export const colors = {
  green: "#00D062",
  greenV2: "#1EDD00",
  danger: "#DC0A0A",
  warning: "#DC8C0A",
  success: "#40DC0A",
  info: "#0A90DC",
};