// LIBs
import styled from "styled-components";

export const TechnologiesContainer = styled.section`
  padding: 120px 0;
  background-color: ${props => props.theme.theme.mode.bg};

  .TechnologiesContainer {
    max-width: 1280px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;

    &__title {
      text-align: center;
      margin-bottom: 20px;

      h3 {
        font-size: ${props => props.theme.sizes.ftXI}px;
        color: ${props => props.theme.theme.mode.font};
        font-weight: 600;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftXIm}vw;
        }
      }
    }

    &__text {
      max-width: 450px;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 40px;
      text-align: center;

      p {
        font-size: ${props => props.theme.sizes.ftR}px;
        color: ${props => props.theme.theme.mode.font};
        line-height: ${props => props.theme.sizes.ftR + 10}px;
        text-transform: uppercase;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftRm}vw;
          line-height: ${props => props.theme.sizes.ftRm + 2}vw;
        }
      }
    }

    &__filter {
      margin-bottom: 60px;

      display: flex;
      justify-content: center;

      label {
        width: 205px;
        display: block;
        text-align: center;

        select {
          text-align: center;
        }
      }
    }

    &__list {
      margin-bottom: 40px;

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;

      @media ${props => props.theme.screens.tb} {
        grid-template-columns: repeat(2, 1fr);
      }

      @media ${props => props.theme.screens.mbB} {
        grid-template-columns: 1fr;
      }

      >div {
        height: 100%;
      }
    }

    &__buttons {
      display: flex;
      justify-content: center;
      gap: 20px;

      @media ${props => props.theme.screens.mbB} {
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;
