// LIBs
import { Link } from "react-router-dom";

// CONFIG
import { useToolkitDispatch } from "../../core/config/store";

// CONSTANTs
import { useTranslate } from "../../core/constants/useTranslate";

// REDUCERs
import { setUser, setToken } from "../../core/reducers/user-reducer";

// ICONs
import { FiX } from "react-icons/fi";

// STYLEs
import { SidebarDashLight } from "./styles";

const data = [
  {
    name: "Meu Perfil",
    to: "/dashboard/my-profile",
  },
  {
    name: "Configurações",
    to: "/dashboard/settings",
  },
  {
    name: "Sair",
    onClick: (callback: any) => callback("exit"),
  },
];

interface ISidebarDashLightLayoutProps {
  show: boolean;
  onHide: any;
}

export function SidebarDashLightLayout({ show, onHide }: ISidebarDashLightLayoutProps) {
  const dispatch = useToolkitDispatch();

  const { t } = useTranslate();

  const logout = () => {
    dispatch(setToken(null));
    dispatch(setUser(null));
  }

  return (
    <SidebarDashLight show={show ? 1 : 0}>
      <div className="SidebarDashLight">
        <div className="SidebarDashLight__header">
          <h2>JSNinja <span>Platform</span></h2>
          <button onClick={onHide}>
            <FiX />
          </button>
        </div>

        <ul>
          {
            t("platform").content?.menu_settings.map((el: any, index: number) => {
              let liClassName = "";

              if (el.to && window.location.href.indexOf(el.to) > -1) liClassName = "active-link";

              return (
                <li 
                  className={liClassName}
                  key={index}
                >
                  { el.to &&
                    <Link 
                      to={`/dashboard${el.to}`}
                      onClick={onHide}
                    >
                      {el.label}
                    </Link>
                  }
                  { el.onClick &&
                    <button
                      onClick={() => {
                        onHide();
                        el.onClick((action: string) => {
                          if (action === "exit") logout();
                        })
                      }}
                    >
                      {el.label}
                    </button>
                  }
                </li>   
              );
            })
          }
        </ul>
      </div>
    </SidebarDashLight>
  );
}
