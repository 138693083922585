export const requestDT_enUS = {
  "Falta paramêtros na requisição!": "The request is missing parameters!",
  "Credenciais inválidas!": "Invalid Credentials!",
  "Você não tem permissão!": "You do not have permission!",
  "Não há nenhum usuário!": "There are no users!",
  "Nenhum usuário encontrado!": "No users found!",
  "ID da hierarquia/status não reconhecidos!": "Hierarchy ID/status not recognized!",
  "Já há um usuário com esse email!": "There is already a user with that email!",
  "Não há nenhum formulário de contato!": "There is no contact form!",
  "Formulário de contato não encontrado!": "Contact form not found!",
  "Faça alterações dos campos!": "Make changes to the fields!",
  "Extensão da imagem não é aceita!": "Image extension is not accepted!",
};
