// LIBs
import styled from "styled-components";

export const CardServicesContainer = styled.div<{ active: number }>`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(315deg, ${props => props.theme.theme.mode.bg} 33.85%, ${props => props.theme.theme.mode.bgGreen} 100%);
  overflow: hidden;
  cursor: pointer;
  
  ${props => props.active === 1 && `
    border-right: 4px solid ${props.theme.theme.green};
  `}

  position: relative;

  .CardServicesContainer {
    height: 100%;
    padding: 60px 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__icon {
      position: absolute;
      top: 10px;
      left: 10px;

      span {
        font-size: ${props => props.theme.sizes.ftBII}px;
        color: ${props => props.theme.theme.mode.font};

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftBIIm}vw;
        }
      }
    }

    &__title {
      text-align: center;
      margin-bottom: 20px;

      p, span {
        font-size: ${props => props.theme.sizes.ftM}px;
        font-weight: 600;
        color: ${props => props.theme.theme.mode.font};

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftMm}vw;
        }
      }
    }

    &__resum {
      text-align: center;

      p {
        font-size: ${props => props.theme.sizes.ftR}px;
        color: ${props => props.theme.theme.mode.font};
        font-weight: 300;
        line-height: ${props => props.theme.sizes.ftR+10}px;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftRm}vw;
          line-height: ${props => props.theme.sizes.ftRm+2}vw;
        }
      }
    }
  }
`;
