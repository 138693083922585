// LIBs
import { ReactNode } from "react";
import { Link } from "react-scroll";

// STYLEs
import { ButtonContainer } from "./styles";

export interface IButtonProps {
  title?: string;
  children?: ReactNode;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  to?: string;
  type?: "button" | "link-external" | "link-scroll";
  color?: "danger";
  duration?: number;
  shadowGreenBlur?: boolean;
  load?: boolean;
  onClick?: any;
}

export function Button({ 
  title, 
  children,
  iconLeft, 
  iconRight, 
  to, 
  type = "button", 
  color,
  duration = 400,
  shadowGreenBlur = false,
  load = false,
  onClick,
}: IButtonProps) {
  const Content = () => {
    if (!load) {
      return (
        <>
          {!children ? title : children}
        </>
      )
    } else {
      return <span className="lds-dual-ring" />;
    }
  }

  return (
    <ButtonContainer color={color} shadowgreenblur={shadowGreenBlur ? 1 : 0}>
      { type === "link-scroll" &&
        <Link
          to={to || ""}
          smooth
          duration={duration}
        >
          <Content />
        </Link>
      }
      { type === "link-external" &&
        <a 
          href={to && to} 
          target="_blank" 
          onClick={onClick && onClick}
        >
          { iconLeft &&
            <span>
              {iconLeft}
            </span>
          }
          <Content />
          { iconRight &&
            <span>
              {iconRight}
            </span>
          }
        </a>
      }
      { type === "button" &&
        <button onClick={onClick && onClick}>
          <Content />
        </button>
      }
    </ButtonContainer>
  );
}
