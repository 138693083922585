// LIBs
import styled from "styled-components";

export const DeleteContainer = styled.div`
  max-width: 450px;
  width: 100%;
  background-color: ${props => props.theme.theme.mode.bg};
  border-radius: 10px;
  border-bottom: 3px solid ${props => props.theme.theme.green};

  .DeleteContainer {
    padding: 30px 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    &__icon {
      display: flex;
      justify-content: center;

      span {
        svg {
          font-size: ${props => props.theme.sizes.ftX * 2}px;
          color: ${props => props.theme.theme.danger};
        }
      }
    }

    &__text {
      text-align: center;

      p {
        font-size: ${props => props.theme.sizes.ft}px;
        color: ${props => props.theme.theme.mode.font};
      }
    }

    &__buttons {
      display: flex;
      gap: 20px;
    }
  }
`;
