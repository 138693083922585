// LIBs
import styled from "styled-components";

export const ContactContainer = styled.section`
  padding: 120px 0;
  background: linear-gradient(110deg, ${props => props.theme.theme.mode.bgGreen} 0%, ${props => props.theme.theme.mode.bg} 100.48%);

  .ContactContainer {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    &__title {
      margin-bottom: 20px;
      text-align: center;

      h3 {
        font-size: ${props => props.theme.sizes.ftXI}px;
        font-weight: 500;
        color: ${props => props.theme.theme.mode.font};

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftXIm}vw;
        }
      }
    }

    &__description {
      max-width: 450px;
      margin: 0 auto;
      margin-bottom: 40px;
      text-align: center;

      p {
        font-size: ${props => props.theme.sizes.ftR}px;
        color: ${props => props.theme.theme.mode.font};
        line-height: ${props => props.theme.sizes.ftR + 10}px;
        text-transform: uppercase;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftRm}vw;
          line-height: ${props => props.theme.sizes.ftRm + 2}vw;
        }
      }
    }

    &__form {
      max-width: 768px;
      width: 100%;
      margin: 0 auto;

      display: flex;
      gap: 30px;
      flex-wrap: wrap;

      @media ${props => props.theme.screens.mbB} {
        flex-direction: column;
      }

      >label {
        flex: 1;
        min-width: calc(50% - 15px);
      }

      >div {
        width: 100%;
        margin-top: 30px;

        display: flex;
        justify-content: center;

        button {
          min-width: 241px;

          display: flex;
          justify-content: center;
        }
      }
    }

    &__status {
      margin-top: 60px;
      text-align: center;

      p {
        font-size: ${props => props.theme.sizes.ft}px;
        color: ${props => props.theme.theme.danger};
      }
    }
  }
`;
