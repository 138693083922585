// LIBs
import Slider from "react-slick";

// COMPONENTs
import { CardServices } from "../../../components/CardServices";
import { Button } from "../../../components/Button";

// CONFIG
import { useToolkitSelector } from "../../../core/config/store";

// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// REDUCERs
import { getScreen } from "../../../core/reducers/app-reducer/selector";

// STYLEs
import { ServicesContainer } from "./styles";

interface IHomeServicesProps {
  serviceIndex: number;
  onChangeSerivce: any;
}

export function HomeServices({ serviceIndex, onChangeSerivce }: IHomeServicesProps) {
  const { t } = useTranslate();
  const screen = useToolkitSelector(getScreen);

  const listServices = () => t("home").services?.list.map((service: any, index: number) => (
    <CardServices 
      Icon={service.technologies[0]}
      title={service.title}
      textResum={service.text_resum}
      active={serviceIndex === index}
      onClick={() => {
        onChangeSerivce(index);
      }}
      key={index}
    />
  ))

  return (
    <ServicesContainer id="services">
      <div className="ServicesContainer">
        <div className="ServicesContainer__list" id="services-list-carousel">
          { screen.width > 450 &&
            listServices()
          }
          { screen.width <= 450 &&
            <Slider
              infinite={false}
              slidesToShow={1.1}
              slidesToScroll={1}
              arrows={false}
              focusOnSelect
              centerMode
              dots
              onReInit={() => {
                const doc = document.querySelectorAll("#services-list-carousel .slick-slide");
                const key = Array.from(doc).findIndex(el => el.classList.contains("slick-current"));
                if (serviceIndex !== key) {
                  onChangeSerivce(key);
                }
              }}
            >
              {listServices()}
            </Slider>
          }
        </div>

        <div className="ServicesContainer__details">
          <div className="ServicesDetails">
            <div className="ServicesDetails__title">
              <h3 
                dangerouslySetInnerHTML={{
                  __html: t("home").services?.list[serviceIndex].title,
                }}
              />
            </div>

            <div className="ServicesDetails__text">
              <p>{t("home").services?.list[serviceIndex].text}</p>
            </div>

            <div className="ServicesDetails__more">
              <div className="ServicesIcons">
                <div className="ServicesIcons__title">
                  <p>{t("home").services?.text_used}</p>
                </div>

                <div className="ServicesIcons__list">
                  {
                    t("home").services?.list[serviceIndex].technologies.map((Icon: any, index: number) => {
                      return (
                        <span key={index}>
                          <Icon />
                        </span>
                      );
                    })
                  } 
                </div>
              </div>

              <div className="ServicesButton">
                <Button 
                  type="link-scroll"
                  to="technologies"
                  title={t("home").services?.button_text} 
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ServicesContainer>
  );
}
