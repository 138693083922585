// LIBs
import styled from "styled-components";

export const Tool = styled.div`
  padding: 30px 20px;
  background-color: ${props => props.theme.theme.mode.bg};
  border-radius: 10px;
  border-bottom: 3px solid ${props => props.theme.theme.green};

  .ToolContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &__title {
      p {
        font-size: ${props => props.theme.sizes.ftBIII}px;
        font-weight: 500;
        color: ${props => props.theme.theme.mode.font};

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftBIIIm}vw;
        }
      }
    }

    &__mode {
      p {
        font-size: ${props => props.theme.sizes.ft}px;
        color: ${props => props.theme.theme.mode.font};
        font-weight: 200;
        margin-bottom: 20px;
        transition: all ease 0.5s;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftm}vw;
        }
      }
    }
  }
`;
