// LIBs
import { useState } from "react";
import { Link } from "react-router-dom";

// CONFIG
import { useToolkitDispatch, useToolkitSelector } from "../../core/config/store";

// CONSTANTs
import { useTranslate } from "../../core/constants/useTranslate";

// REDUCERs
import { setToken, setUser, setMenuShown } from "../../core/reducers/user-reducer";
import { getUser } from "../../core/reducers/user-reducer/selector";

// ICONs
import { FiUser, FiChevronDown, FiMenu } from "react-icons/fi";

// STYLEs
import { HeaderDash, ToolBoxContainer } from "./styles";

interface IToolBoxProps {
  shown: boolean;
  onLogout: any;
}

function ToolBox({ shown, onLogout }: IToolBoxProps) {
  const { t } = useTranslate();

  return (
    <ToolBoxContainer show={shown ? 1 : 0}>
      <div className="ToolBoxContainer">
        <ul>
          {
            t("platform").content?.toolbox.map((tool: any, index: number) => (
              <li key={index}>
                { tool.to &&
                  <Link 
                    to={`/dashboard${tool.to}`} 
                    key={index}
                  >
                    {tool.label}
                  </Link>
                }
                { tool.onClick &&
                  <button
                    onClick={() => {
                      tool.onClick((action: string) => {
                        if (action === "exit") onLogout();
                      });
                    }}
                  >
                    {tool.label}
                  </button>
                }
              </li>
            ))
          }
        </ul>
      </div>
    </ToolBoxContainer>
  );
}

export function HeaderDashLayout() {
  const dispatch = useToolkitDispatch();
  
  const user = useToolkitSelector(getUser);

  const [ shownTool, setShownTool ] = useState<boolean>(false);

  let headerDashMoreClass = "HeaderDashMore";

  return (
    <HeaderDash>
      <div className="HeaderDash">
        <div className="HeaderDash__left">
          <div className="HeaderDashOpen">
            <button
              onClick={() => {
                dispatch(setMenuShown(true));
              }}
            >
              <FiMenu />
            </button>
          </div>
        </div>

        <div className="HeaderDash__right">
          <div className="HeaderDashImage">
            { user?.photo 
                ? <img src={user.photo} alt="" />
                : <span>
                    <FiUser />
                  </span>
            }
          </div>

          <div className="HeaderDashText">
            <p>{user?.name}</p>
          </div>

          <div 
            className={headerDashMoreClass}
            onMouseLeave={() => {
              setShownTool(false);
            }}
          >
            <span 
              onClick={() => {
                setShownTool(true);
              }}
            >
              <FiChevronDown />
            </span>

            <ToolBox 
              shown={shownTool} 
              onLogout={() => {
                dispatch(setToken(null));
                dispatch(setUser(null));
              }}
            />
          </div>
        </div>
      </div>
    </HeaderDash>
  );
}
