// LIBs
import axios from "axios";

// CONFIG
import appConfig from "./app";

const api = axios.create({
  baseURL: appConfig.requestURL,
});

export const setToken = (token: string) => {
  api.defaults.params = { 'token': token };
}

export default api;
