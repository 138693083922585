// LIBs
import { isAxiosError, AxiosError } from "axios";
import jsCookie from "js-cookie";

// CONFIG
import api, { setToken } from "../config/axios";

// REDUCERs
import { tokenName } from "../reducers/user-reducer";

const token = jsCookie.get(tokenName);

if (token) {
  setToken(token);
}

const convertDataToFormData = (data: any) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  
  if (keys.length > 0) {
    keys.forEach(key => {
      formData.append(key, data[key]);
    });
  }

  return formData;
}

/**
 * Contacts - Requests
 */
export const sendContact = async (data: any) => {
  try {
    const formData = convertDataToFormData(data);
    const req = await api.post("/contact", formData);
    return req.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const data = axiosError.response.data as any;
        return data;
      }
    }
    return error;
  }
}

/**
 * Users - Requests
 */
export const getEmailAndPass = async (data: any) => {
  try {
    const formData = convertDataToFormData(data);
    const req = await api.post("/users/sign_in", formData);
    return req.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const data = axiosError.response.data as any;
        return data;
      }
    }
    return error;
  }
}

export const getMe = async () => {
  try {
    const req = await api.get("/users/me");
    return req.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const data = axiosError.response.data as any;
        return data;
      }
    }
    return error;
  }
}

export const getUsers = async () => {
  try {
    const req = await api.get("/users");
    return req.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const data = axiosError.response.data as any;
        return data;
      }
    }
    return error;
  }
}

export const getUser = async (id: string) => {
  try {
    const req = await api.get(`/users/${id}`);
    return req.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const data = axiosError.response.data as any;
        return data;
      }
    }
    return error;
  }
}

export const createUser = async (data: any) => {
  try {
    const formData = new FormData();
    const keys = Object.keys(data);
    keys.forEach((key) => formData.append(key, data[key]));
    const req = await api.post("/users", formData);
    return req.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const data = axiosError.response.data as any;
        return data;
      }
    }
    return error;
  }
}

export const updateUser = async (id: string, data: any, user: any) => {
  try {
    const formData = new FormData();
    formData.append("_method", "edit");
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (data[key] !== user[key] && data[key].trim()) {
        formData.append(key, data[key]);
      }
    });
    const req = await api.post(`/users/${id}`, formData);
    return req.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const data = axiosError.response.data as any;
        return data;
      }
    }
    return error;
  }
}

export const updateMe = async (data: any, user: any) => {
  try {
    const formData = new FormData();
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (data[key] !== user[key] && typeof data[key] === "string" && data[key].trim()) {
        formData.append(key, data[key]);
      } else if (typeof data[key] !== "string") {
        formData.append(key, data[key]);
      }
    }); 
    const req = await api.post(`/users/me/edit`, formData);
    return req.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const data = axiosError.response.data as any;
        return data;
      }
    }
    return error;
  }
}

export const deleteUser = async (id: string) => {
  try {
    const formData = new FormData();
    formData.append("_method", "delete");
    const req = await api.post(`/users/${id}`, formData);
    return req.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const data = axiosError.response.data as any;
        return data;
      }
    }
    return error;
  }
}

/**
 * Contacts - Requests
 */
export const getContacts = async () => {
  try {
    const req = await api.get("/contact");
    return req.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const data = axiosError.response.data as any;
        return data;
      }
    }
    return error;
  }
}

export const getContact = async (id: string) => {
  try {
    const req = await api.get(`/contact/${id}`);
    return req.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const data = axiosError.response.data as any;
        return data;
      }
    }
    return error;
  }
}

export const deleteContact = async (id: string) => {
  try {
    const formData = new FormData();
    formData.append("_method", "delete");
    const req = await api.post(`/contact/${id}`, formData);
    return req.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const data = axiosError.response.data as any;
        return data;
      }
    }
    return error;
  }
}

/**
 * Config Hierarchy - Requests
 */
export const getHierarchies = async () => {
  try {
    const req = await api.get("/config_hierarchy");
    return req.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const data = axiosError.response.data as any;
        return data;
      }
    }
    return error;
  }
}

/**
 * Config Status = Requests
 */
export const getStatusList = async () => {
  try {
    const req = await api.get("/config_status");
    return req.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const data = axiosError.response.data as any;
        return data;
      }
    }
    return error;
  }
}
