// LIBs
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";

// REDUCERs
import appReducer, { IInitialStateAppTypes } from "../reducers/app-reducer";
import userReducer, { IInitialStateUserTypes } from "../reducers/user-reducer";

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
  },
});

type AppDispatch = typeof store.dispatch;

export interface RootState {
  app: IInitialStateAppTypes;
  user: IInitialStateUserTypes;
};

export const useToolkitDispatch = () => useDispatch<AppDispatch>();
export const useToolkitSelector: TypedUseSelectorHook<RootState> = useSelector;
