// CONTAINERs
import { SettingsForm } from "../../containers/settings/form";

export function SettingsView() {
  return (
    <main className="SettingsWrapper">
      <SettingsForm />
    </main>
  );
}
