// LIBs
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

// COMPONENTs
import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";

// CONFIG
import { useToolkitSelector } from "../../../core/config/store";

// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// REDUCERs
import { getUser } from "../../../core/reducers/user-reducer/selector";

// UTILs
import { isEmail, isPassword, isText } from "../../../core/utils/validator";

// ICONs
import { FiUser } from "react-icons/fi";

// STYLEs
import { Form } from "./styles";

interface IMyProfileFormProps {
  onSend: any;
}

let nameTimeout: any;
let emailTimeout: any;
let passwordTimeout: any;

export function MyProfileForm({ onSend }: IMyProfileFormProps) {
  const user = useToolkitSelector(getUser);

  const { t } = useTranslate();

  const [ load, setLoad ] = useState<boolean>(false);
  const [ photo, setPhoto ] = useState<null | string | any>(null);
  const [ name, setName ] = useState<string>("");
  const [ nameError, setNameError ] = useState<string>("");
  const [ email, setEmail ] = useState<string>("");
  const [ emailError, setEmailError ] = useState<string>("");
  const [ password, setPassword ] = useState<string>("");
  const [ passwordError, setPasswordError ] = useState<string>("");

  useEffect(() => {
    fillInForm();
  }, [user])

  const fillInForm = () => {
    if (user) {
      setPhoto(user.photo);
      setName(user.name);
      setEmail(user.email);
    }
  }

  const validateForm = () => {
    const newNameError = isText(name, (qtdCarac: number) => {
      return t("common").inputs?.text_invalid.replace("qtdCarac", qtdCarac);
    });
    const newEmailError = isEmail(email, t("common").inputs?.email_invalid);
    const newPasswordError = password.trim() !== "" ? isPassword(password, t("common").inputs?.password_invalid) : "";

    setNameError(newNameError);
    setEmailError(newEmailError);
    setPasswordError(newPasswordError);

    return !newNameError && !newEmailError && !newPasswordError;
  }

  const send = () => {
    setLoad(true);

    if (validateForm()) {
      onSend(name, email, password, photo);
    }

    setTimeout(() => setLoad(false), 1000);
  }

  return (
    <Form>
      <div className="MyProfileForm">
        <div className="MyProfileForm__header">
          <h1>{t("myProfile").form?.title}</h1>
        </div>

        <div className="MyProfileForm__profile">
          <p>{t("common").inputs?.inputs.photo.label}</p>
          <label>
            { !photo &&
              <span>
                <FiUser />
              </span>
            }
            { photo &&
              <img src={typeof photo === "string" ? photo : URL.createObjectURL(photo)} alt="" /> 
            }
            <input 
              type="file" 
              onChange={(event) => {
                const files = event.target.files;
                if (files && files.length > 0) {
                  if (files[0].type === "image/png" || files[0].type === "image/jpg" || files[0].type === "image/jpeg") {
                    setPhoto(files[0]);
                  } else {
                    toast.warn(t("common").request["Extensão da imagem não é aceita!"]);
                  }
                }
              }}
              accept="image/png,image/jpg,image/jpeg"
            />
          </label>
        </div>

        <div className="MyProfileForm__inputs">
          <Input 
            label={t("common").inputs?.inputs.name.label}
            value={name}
            placeholder={t("common").inputs?.inputs.name.placeholder}
            readOnly={load}
            error={nameError}
            onChange={(event: any) => {
              setName(event.target.value);
              clearTimeout(nameTimeout);
              nameTimeout = setTimeout(() => {
                setNameError(isText(event.target.value, (qtdCarac: number) => {
                  return t("common").inputs?.text_invalid.replace("qtdCarac", qtdCarac);
                }));
              }, 1000);
            }}
          />
          <Input 
            label={t("common").inputs?.inputs.email.label}
            value={email}
            placeholder={t("common").inputs?.inputs.name.placeholder}
            readOnly={load}
            error={emailError}
            onChange={(event: any) => {
              setEmail(event.target.value);
              clearTimeout(emailTimeout);
              emailTimeout = setTimeout(() => {
                setEmailError(isEmail(event.target.value, t("common").inputs?.email_invalid));
              }, 1000);
            }}
          />
          <Input 
            type="password"
            label={t("common").inputs?.inputs.password.label}
            value={password}
            readOnly={load}
            placeholder={t("common").inputs?.inputs.password.placeholder}
            error={passwordError}
            onChange={(event: any) => {
              setPassword(event.target.value);
              clearTimeout(passwordTimeout);
              passwordTimeout = setTimeout(() => {
                if (event.target.value.trim() !== "") {
                  setPasswordError(isPassword(event.target.value, t("common").inputs?.password_invalid));
                } else {
                  setPasswordError("");
                }
              }, 1000);
            }}
          />
          
          { user &&
            <div className="MyProfileDetails">
              <div>
                <p>{t("common").inputs?.inputs.status.label}</p>
                <span>{user.config_status.name}</span>
              </div>

              <div>
                <p>{t("common").inputs?.inputs.hierarchy.label}</p>
                <span>{user.config_hierarchy.name}</span>
              </div>
            </div>
          }
        </div>

        <div className="MyProfileForm__button">
          <Button 
            title={t("myProfile").form?.btn_save}
            load={load}
            onClick={send}
          /> 
        </div>
      </div>
    </Form>
  );
}
