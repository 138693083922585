// LIBs
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jsCookie from "js-cookie";

// UTILs
import { setToken as setTokenApi } from "../../config/axios";

// TYPEs
import { IUserTypes } from "../../../types/usersTypes";

export interface IInitialStateUserTypes {
  token: string | null;
  menuShown: boolean;
  user: IUserTypes | null,
};

const initialState: IInitialStateUserTypes = {
  token: null,
  menuShown: false,
  user: null,
};

export const tokenName = "@jsninja::token";
let token = jsCookie.get(tokenName);

if (token) {
  initialState.token = token;
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
      if (action.payload) {
        jsCookie.set(tokenName, action.payload, {
          expires: 7,
        });
        setTokenApi(action.payload);
      } else {
        jsCookie.remove(tokenName);
        setTokenApi("");
      }
    },
    setUser: (state, action: PayloadAction<IUserTypes | null>) => {
      state.user = action.payload;
    },
    setMenuShown: (state, action: PayloadAction<boolean>) => {
      state.menuShown = action.payload;
    },
  },
});

export const { setToken, setUser, setMenuShown } = userSlice.actions;
export default userSlice.reducer;
