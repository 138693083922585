// LIBs
import { useLayoutEffect, ReactNode } from "react";
import { Navigate, useNavigate } from "react-router-dom";

// CONFIG
import { useToolkitSelector, useToolkitDispatch } from "../../core/config/store";

// CONSTANTs
import { getToken } from "../../core/reducers/user-reducer/selector"; 

// REDUCERs
import { setUser } from "../../core/reducers/user-reducer";
import { getScreen } from "../../core/reducers/app-reducer/selector";

// UTILs
import { getMe } from "../../core/utils/Api";

// LAYOUT
import { TabBarLeftDashLayout } from "../../layout/tab-left-bar-dash";
import { HeaderDashLayout } from "../../layout/header-dash";
import { ContentDashLayout } from "../../layout/content-dash";

interface IDashboardTemplateProps {
  children: ReactNode;
}

const to = "/dashboard/sign-in";

export function DashboardTemplate({ children }: IDashboardTemplateProps) {
  const token = useToolkitSelector(getToken);
  const screen = useToolkitSelector(getScreen);

  const navigate = useNavigate();
  const dispatch = useToolkitDispatch();

  useLayoutEffect(() => {
    if (token) fetchUser();
  }, [])

  const fetchUser = async () => {
    const req = await getMe();
    if (req.data) {
      dispatch(setUser(req.data.user));
    } else {
      navigate(to);
    }
  }

  if (token) {
    return (
      <div 
        style={{
          display: "flex",
        }}
      >
        <TabBarLeftDashLayout />
        <div
          style={{
            width: screen.width > 768 ? "calc(100vw - 400px)" : "100vw",
            height: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <HeaderDashLayout />
          <ContentDashLayout>
            {children}
          </ContentDashLayout>
        </div>
      </div>
    );
  } else {
    return <Navigate to={to} />
  }
}
