// LIBs
import { Link } from "react-scroll";

// STYLEs
import { MenuContainer } from "./styles";

export interface IMenuDataTypes {
  label: string;
  to: string;
}

export interface IMenuProps {
  data: IMenuDataTypes[];
  activeLink: string;
  shown: boolean;
  onChangeShown?: any;
}

export function Menu({ data, activeLink, shown, onChangeShown }: IMenuProps) {
  return (
    <MenuContainer show={shown ? 1 : 0}>
      {
        data.map((linkMenu: any, index: number) => (
          <li 
            className={linkMenu.to === activeLink ? "active-linkMenu" : ""} 
            key={index}
          >
            <Link 
              to={linkMenu.to}
              onClick={() => {
                onChangeShown && onChangeShown(false);
              }}
              duration={400}
              smooth
            >
              {linkMenu.label}
            </Link>
          </li>
        ))
      }
    </MenuContainer>
  );
}
