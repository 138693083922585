// LIBs
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// CONTAINERs
import { SignInLogin } from "../../containers/sign-in/login";

// CONFIG
import { useDispatch } from "react-redux";

// CONSTANTs
import { useTranslate } from "../../core/constants/useTranslate";

// REDUCERs
import { setToken } from "../../core/reducers/user-reducer";

// UTILs
import { getEmailAndPass } from "../../core/utils/Api";

export function SignInView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const fetchUser = async (email: string, password: string) => {
    const data = { email, password };
    const tokenRequest = await getEmailAndPass(data);
    if (tokenRequest.data) {
      dispatch(setToken(tokenRequest.data.token));
      navigate("/dashboard");
    } else {
      toast.error(t("signIn").content?.form_request[tokenRequest.error.message]);
    }
  }

  return (
    <main className="SignInWrapper">
      <SignInLogin 
        onSend={fetchUser}
      />
    </main>
  );
}
