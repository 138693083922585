const clearText = (text: string) => text.replace(/[^\w\s]|_/gi, '').replace(/\s+/g, '');

export const isText = (text: string, errorPhrase: any, qtdCarac: number = 3) => {
  let response = "";
  if (!(text.trim().length > qtdCarac)) response = typeof errorPhrase === "string" ? errorPhrase : errorPhrase(qtdCarac);
  return response;
}

export const isEmail = (text: string, errorPhrase: any) =>{
  let response = "";
  const regexGeral = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!regexGeral.test(text)) response = typeof errorPhrase === "string" ? errorPhrase : errorPhrase();
  return response;
}

export const isPhone = (text: string, errorPhrase: any) => {
  let response = "";
  if (clearText(text).length !== 11) response = typeof errorPhrase === "string" ? errorPhrase : errorPhrase();
  return response;
}

export const isPassword = (password: string, errorPhrases: any) => {
  let response = "";
  if (!/[!@#$%^&*()_+={}[\]:;<>,.]/.test(password))
    response = errorPhrases.special;
  if (!/\d/.test(password))
    response = errorPhrases.number;
  if (!/[A-Z]/.test(password))
    response = errorPhrases.uppercase;
  if (password.length < 8)
    response = errorPhrases.carac;
  return response;
}
