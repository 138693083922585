export const contentDT_enUS = {
  title: "Contact list",
  table: {
    subject: "Subject:",
    email: "Email:",
    actions: "Actions:",
  },
  subtitle: "View Contact - contactId",
  phrase: "Do you want to delete this contact?",
  contact_deleted: "Contact deleted successfully!",
};
