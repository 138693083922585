// LIBs
import styled from "styled-components";

export const List = styled.div`
  margin-bottom: 40px;

  .List {
    &__header {
      margin-bottom: 30px;

      h1 {
        font-size: ${props => props.theme.sizes.ftXI}px;
        font-weight: 600;
        color: ${props => props.theme.theme.mode.font};

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftXIm}vw;
        }
      }

      &::after {
        content: '';
        max-width: 75px;
        width: 100%;
        height: 3px;
        background-color: ${props => props.theme.theme.green};
        margin-top: 10px;

        display: block;
      }
    }

    &__table {
      .Links {
        display: flex;
        gap: 10px;

        a {
          padding: 10px;
          font-size: ${props => props.theme.sizes.ftM}px;
          color: ${props => props.theme.theme.danger};
          cursor: pointer;

          &:first-child {
            color: ${props => props.theme.theme.info};
          }
        }

        p {
          font-size: ${props => props.theme.sizes.ftR}px;
          font-weight: 600;
          color: ${props => props.theme.theme.mode.font};
        }
      }
    }
  }
`;
