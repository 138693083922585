export const contentDT_enUS = {
  title: "Connect to <span>JSNinja Platform</span>",
  text: "CONNECT NOW AND FOLLOW THE DEVELOPMENT OF YOUR PROJECTS WITH US!",
  email_input: {
    label: "Email:",
    placeholder: "Type your e-mail...",
  },
  password_input: {
    label: "Password:",
    placeholder: "Type your password...",
  },
  btn_label: "Login",
  inputs_invalid: "Fill in the fields correctly!",
  form_request: {
    "Credenciais inválidas!": "Invalid Credentials!",
  },
};