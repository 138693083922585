// LIBs
import styled from "styled-components";

export const ButtonContainer = styled.div<{ color?: "danger", shadowgreenblur: number }>`
  button,
  a {
    padding: 15px 43px;
    background-color: ${props => props.theme.theme.mode.bgGreen+"66"};
    font-size: ${props => props.theme.sizes.ft}px;
    text-transform: uppercase;
    color: ${props => props.theme.theme.green};
    transition: all ease 0.5s;
    cursor: pointer;

    display: flex;
    justify-content: center;

    @media ${props => props.theme.screens.mbB} {
      font-size: ${props => props.theme.sizes.ftm}vw;
    }

    ${props => props.color === "danger" ? `
      background-color: ${props.theme.theme.danger+"66"};
      color: ${props.theme.theme.danger};
    ` : ""}

    ${props => props.shadowgreenblur === 1 && `
      box-shadow: 0 0 100px 10px ${props.theme.theme.green+"99"};
      text-shadow: 0 0 10px ${props.theme.theme.green};
      -webkit-text-stroke: ${props.theme.theme.green} 0.5px;

      &:hover {
        box-shadow: 0 0 1px ${props.theme.theme.green+"99"};
        text-shadow: 0 0 0 ${props.theme.theme.green};
        -webkit-text-stroke: ${props.theme.theme.mode.bg} 0.5px;
      }
    `}

    span {
      font-size: ${props => props.theme.sizes.ftBII}px;
      color: ${props => props.theme.theme.green};
      transition: all ease 0.5s;

      display: flex;
      justify-content: center;
      align-items: center;

      ${props => props.color === "danger" ? `
        color: ${props.theme.theme.danger};
      ` : ""}
    }

    .lds-dual-ring {
      display: inline-block;
      width: 17px;
      height: 17px;

      display: flex;
      justify-content: center;
      align-items: center;

      position: relative;

      &::after {
        content: " ";
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid #dfc;
        border-color: #dfc transparent #dfc transparent;
        transform: translate(-50%, -50%);
        animation: lds-dual-ring 1.2s linear infinite;

        position: absolute;
      }
    }

    &:hover {
      color: ${props => props.theme.theme.mode.bg};
      background-color: ${props => props.theme.theme.green};

      ${props => props.color === "danger" ? `
        background-color: ${props.theme.theme.danger};
      ` : ""}

      span {
        color: ${props => props.theme.theme.mode.bg};
      }
    }
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
