// LIBs
import styled from "styled-components";

export const Header = styled.header<{ show: number, bgshow: number }>`
  width: 100%;
  padding: 20px 0;
  transition: all ease 0.5s;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  ${props => props.show === 1 && `
    background-color: ${props.theme.theme.mode.bg+"6"};
    backdrop-filter: blur(5px);
  `}

  ${props => props.bgshow === 1 && `
    background-color: ${props.theme.theme.mode.bg+"6"};
    backdrop-filter: blur(5px);
  `}

  .HeaderContainer {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${props => props.theme.screens.tb} {
      max-width: 450px;
    }

    &__logo {
      a {
        cursor: pointer;

        img {
          width: 75px;

          @media ${props => props.theme.screens.mbB} {
            width: 13.3vw;
          }
        }
      }
    }

    &__nav {
      @media ${props => props.theme.screens.tb} {
        width: 100%;
        height: calc(100vh - 100%);
        background: linear-gradient(110deg, ${props => props.theme.theme.mode.bgGreen} 0%, ${props => props.theme.theme.mode.bg} 100.48%);
        border-top: 2px solid ${props => props.theme.theme.mode.font};
        opacity: 0;
        pointer-events: none;
        transition: all ease 0.5s;

        position: absolute;
        top: 100%;
        left: 0;
        z-index: 10;

        ${props => props.show && `
          opacity: 1;
          pointer-events: all;
        `}
      }
      
      nav {
        @media ${props => props.theme.screens.tb} {
          width: 100%;
          height: 100%;
          padding: 20px;

          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &__btn {
      display: none;

      @media ${props => props.theme.screens.tb} {
        display: block;
      }

      button {
        width: 50px;
        height: 50px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;

        position: relative;

        @media ${props => props.theme.screens.mbB} {
          width: 8.8vw;
          height: 8.8vw;

          gap: 2.6vw;
        }

        div {
          width: 100%;
          height: 5px;
          background-color: ${props => props.theme.theme.mode.font};
          transition: all ease 0.5s;

          @media ${props => props.theme.screens.mbB} {
            height: 0.6vw;
          }

          &:nth-of-type(1) {
            ${props => props.show && `
              margin-bottom: -20px;
              transform: rotate(-45deg);
            `}

            @media ${props => props.theme.screens.mbB} {
              ${props => props.show && `
                margin-bottom: -3.2vw;
                transform: rotate(-45deg);
              `}
            }
          }

          &:nth-of-type(2) {
            ${props => props.show && `
              opacity: 0;
            `}
          }

          &:nth-of-type(3) {
            ${props => props.show && `
              margin-top: -20px;
              transform: rotate(45deg);
            `}

            @media ${props => props.theme.screens.mbB} {
              ${props => props.show && `
                margin-top: -3.2vw;
                transform: rotate(45deg);
              `}
            }
          }
        }
      }
    }
  }
`;
