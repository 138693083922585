// STYLEs
import { TableContainer } from "./styles";

export interface ITableColumnsTypes {
  label: string;
  name: string;
  renderItem?: any;
}

export interface ITableProps {
  columns: ITableColumnsTypes[];
  rows: any[];
}

export function Table({ columns, rows }: ITableProps) {
  return (
    <TableContainer>
      <div className="TableContainer">
        <div className="TableContainer__list">
          <table width="100%">
            <thead>
              <tr>
                { 
                  columns.map((column, index) => (
                    <th key={index}>
                      {column.label}
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              { rows.length > 0 &&
                rows.map((row, index) => {
                  return (
                    <tr key={index}>
                      {
                        columns.map((column, index) => (
                          <td key={index}>
                            { !column.renderItem 
                                ? row[column.name]
                                : column.renderItem(row[column.name], row, rows)
                            }
                          </td>
                        ))
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </TableContainer>
  );
}
