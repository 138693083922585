// LIBs
import styled from "styled-components";

export const Login = styled.section<{ load: number }>`
  width: 100%;
  min-height: 100vh;
  background-color: ${props => props.theme.theme.mode.bg};
  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  .Login {
    max-width: 450px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      text-align: center;
      margin-bottom: 20px;

      h1, span {
        font-size: ${props => props.theme.sizes.ftXI}px;
        font-weight: 500;
        color: ${props => props.theme.theme.mode.font};

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftXIm}vw;
        }
      }

      h1 {
        display: flex;
        flex-direction: column;
      }

      span {
        color: ${props => props.theme.theme.green};
      }
    }

    &__text {
      max-width: 375px;
      text-align: center;
      margin-bottom: 40px;

      p {
        font-size: ${props => props.theme.sizes.ftR}px;
        color: ${props => props.theme.theme.mode.font};
        line-height: ${props => props.theme.sizes.ftR + 10}px;
        text-transform: uppercase;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftRm}vw;
          line-height: ${props => props.theme.sizes.ftRm + 2}vw;
        }
      }
    }

    &__form {
      width: 100%;
      margin-bottom: 60px;

      display: flex;
      flex-direction: column;
      gap: 30px;

      ${props => props.load === 1 ? `
        opacity: 0.5;
        pointer-events: none;
      ` : ""}

      label {
        &:hover {
          button {
            svg {
              color: ${props => props.theme.theme.green};
            }
          }
        }
      }

      &_button {
        svg {
          font-size: ${props => props.theme.sizes.ftBII}px;
          color: ${props => props.theme.theme.mode.font};
          transition: all ease 0.5s;
        }
      }
    }
  }
`;
