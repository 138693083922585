// LIBs
import { useState, useEffect } from "react";

// COMPONENTs
import { Input } from "../../../components/Input";
import { Button } from "../../../components/Button";

// CONSTANTs
import { useTranslate } from "../../../core/constants/useTranslate";

// UTILs
import { isEmail, isPhone, isText } from "../../../core/utils/validator";

// STYLEs
import { ContactContainer } from "./styles";

const formSend = "@levyalves:portfolio_v2:contact";

interface IHomeContactProps {
  onSend: any;
}

let nameTimeout: any;
let emailTimeout: any;
let phoneTimeout: any;
let subjectTimeout: any;
let aboutTimeout: any;

export function HomeContact({ onSend }: IHomeContactProps) {
  const { t, language } = useTranslate();

  const [ name, setName ] = useState<string>("");
  const [ nameError, setNameError ] = useState<string>("");
  const [ email, setEmail ] = useState<string>("");
  const [ emailError, setEmailError ] = useState<string>("");
  const [ phone, setPhone ] = useState<string>("");
  const [ phoneError, setPhoneError ] = useState<string>("");
  const [ subject, setSubject ] = useState<string>("");
  const [ subjectError, setSubjectError ] = useState<string>("");
  const [ about, setAbout ] = useState<string>("");
  const [ aboutError, setAboutError ] = useState<string>("");
  const [ load, setLoad ] = useState<boolean>(false);

  useEffect(() => {
    validateForm(true);
  }, [language])

  const clear = () => {
    setName("");
    setEmail("");
    setPhone("");
    setSubject("");
    setAbout("");
  }

  const validateForm = (isError = false) => {
    const newNameError = isText(name, (qtdCarac: number) => {
      return t("home").contact?.text_invalid.replace("qtdCarac", qtdCarac);
    });
    const newEmailError = isEmail(email, t("home").contact?.email_invalid);
    const newPhoneError = isPhone(phone, t("home").contact?.phone_invalid);
    const newSubjectError = isText(subject, (qtdCarac: number) => {
      return t("home").contact?.text_invalid.replace("qtdCarac", qtdCarac);
    }, 6);
    const newAboutError = isText(about, (qtdCarac: number) => {
      return t("home").contact?.text_invalid.replace("qtdCarac", qtdCarac);
    }, 10);

    if (!isError) {
      setNameError(newNameError);
      setEmailError(newEmailError);
      setPhoneError(newPhoneError);
      setSubjectError(newSubjectError);
      setAboutError(newAboutError);
    } else {
      if (nameError) setNameError(newNameError);
      if (emailError) setEmailError(newEmailError);
      if (phoneError) setPhoneError(newPhoneError);
      if (subjectError) setSubjectError(newSubjectError);
      if (aboutError) setAboutError(newAboutError);
    }

    return !newNameError && !newEmailError && !newPhoneError && !newSubjectError && !newAboutError;
  }

  const send = async () => {
    setLoad(true);
    
    if (validateForm()) {
      const result = await onSend(name, email, phone, subject, about);
      if (result) clear();
    }
    
    setTimeout(() => setLoad(false), 1000);
  }

  return (
    <ContactContainer id="contact">
      <div className="ContactContainer">
        <div className="ContactContainer__title">
          <h3>{t("home").contact?.title}</h3>
        </div>

        <div className="ContactContainer__description">
          <p>{t("home").contact?.description}</p>
        </div>

        <div className="ContactContainer__form">
          <Input 
            label={t("home").contact?.label_name}
            value={name}
            onChange={(event: any) => {
              setName(event.target.value);
              clearTimeout(nameTimeout);
              nameTimeout = setTimeout(() => {
                setNameError(isText(event.target.value, (qtdCarac: number) => {
                  return t("home").contact?.text_invalid.replace("qtdCarac", qtdCarac);
                }));
              }, 1000);
            }}
            error={nameError}
            placeholder={t("home").contact?.placeholder_name}
            readOnly={load}
          />
          <Input 
            label={t("home").contact?.label_email}
            value={email}
            onChange={(event: any) => {
              setEmail(event.target.value);
              clearTimeout(emailTimeout);
              emailTimeout = setTimeout(() => {
                setEmailError(isEmail(event.target.value, t("home").contact?.email_invalid));
              }, 1000);
            }}
            error={emailError}
            placeholder={t("home").contact?.placeholder_email}
            readOnly={load}
          />
          <Input 
            mask="(99) 9 9999-9999"
            label={t("home").contact?.label_phone}
            value={phone}
            onChange={(event: any) => {
              setPhone(event.target.value);
              clearTimeout(phoneTimeout);
              phoneTimeout = setTimeout(() => {
                setPhoneError(isPhone(event.target.value, t("home").contact?.phone_invalid));
              }, 1000);
            }}
            error={phoneError}
            placeholder={t("home").contact?.placeholder_phone}
            readOnly={load}
          />
          <Input 
            label={t("home").contact?.label_subject}
            value={subject}
            onChange={(event: any) => {
              setSubject(event.target.value);
              clearTimeout(subjectTimeout);
              subjectTimeout = setTimeout(() => {
                setSubjectError(isText(event.target.value, (qtdCarac: number) => {
                  return t("home").contact?.text_invalid.replace("qtdCarac", qtdCarac);
                }, 6));
              }, 1000);
            }}
            error={subjectError}
            placeholder={t("home").contact?.placeholder_subject}
            readOnly={load}
          />
          <Input 
            type="textarea"
            label={t("home").contact?.label_text}
            value={about}
            onChange={(event: any) => {
              setAbout(event.target.value);
              clearTimeout(aboutTimeout);
              aboutTimeout = setTimeout(() => {
                setAboutError(isText(event.target.value, (qtdCarac: number) => {
                  return t("home").contact?.text_invalid.replace("qtdCarac", qtdCarac);
                }, 10));
              }, 1000);
            }}
            error={aboutError}
            placeholder={t("home").contact?.placeholder_text}
            readOnly={load}
          />
          <Button 
            onClick={send}
            shadowGreenBlur
            load={load}
          >
            {t("home").contact?.button_contact}
          </Button>
        </div>
      </div>
    </ContactContainer>
  );
}
