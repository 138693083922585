// LIBs
import { Link } from "react-router-dom";

// COMPONENTs
import { Swicth } from "../../components/Switch";

// CONFIG
import { useToolkitSelector, useToolkitDispatch } from "../../core/config/store";

// CONSTANTs
import { useTranslate } from "../../core/constants/useTranslate";

// REDUCERs
import { setMode } from "../../core/reducers/app-reducer";
import { getMode } from "../../core/reducers/app-reducer/selector";
import { setMenuShown } from "../../core/reducers/user-reducer";
import { getMenuShown, getUser } from "../../core/reducers/user-reducer/selector";

// ICONs
import { FiX } from "react-icons/fi";

// STYLEs
import { TabLeftBarDash } from "./styles";

interface IRoutesTypes {
  Icon: any;
  to: string;
  label: string;
};

export function TabBarLeftDashLayout() {
  const dispatch = useToolkitDispatch();
  const { t } = useTranslate();

  const mode = useToolkitSelector(getMode);
  const menuShown = useToolkitSelector(getMenuShown);
  const user = useToolkitSelector(getUser);

  const pathname = window.location.pathname.replace("/dashboard", "");
  const access = user ? JSON.parse(user.config_hierarchy.access) : null;

  return (
    <TabLeftBarDash show={menuShown ? 1 : 0}>
      <div className="TabLeftBarDash">
        <div className="TabLeftBarDash__close">
          <button
            onClick={() => {
              dispatch(setMenuShown(false));
            }}
          >
            <FiX />
          </button>
        </div>

        <div className="TabLeftBarDash__logo">
          <Link to="/dashboard">
            <h1>JSNinja <span>Platform</span></h1>
          </Link>
        </div>

        <div className="TabLeftBarDash__nav">
          <nav>
            <ul>
              {
                t("platform").content?.menu.map((route: any, index: number) => {
                  const Icon = route.Icon;
                  let actionClass = "";
                  if (route.to === pathname) actionClass = "active-link";
                  if (access && route.is_access && access[route.is_access]) {
                    return (
                      <li 
                        className={actionClass}
                        key={index}
                      >
                        <Link to={`/dashboard${route.to}`}>
                          <div>
                            <Icon />
                            <p>{route.label}</p>
                          </div>
                        </Link>
                      </li>
                    );
                  }
                })
              }
            </ul>
          </nav>
        </div>

        <div className="TabLeftBarDash__footer">
          <p>{t("platform").content?.mode_label}</p>
          <Swicth 
            value={mode === "dark"}
            onChecked={() => {
              dispatch(setMode(mode === "dark" ? "light" : "dark"));
            }}
          />
        </div>
      </div>
    </TabLeftBarDash>
  );
}
