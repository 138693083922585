// LIBs
import { ReactNode } from "react";

// LAYOUT
import { HeaderLayout } from "../../layout/header";
import { FooterLayout } from "../../layout/Footer";

interface IPortfolioTemplateProps {
  children: ReactNode;
}

export function PortfolioTemplate({ children }: IPortfolioTemplateProps) {
  return (
    <>
      <HeaderLayout />
      {children}
      <FooterLayout />
    </>
  );
}