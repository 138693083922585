// LIBs
import styled from "styled-components";

export const Explorer = styled.section`
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-color: ${props => props.theme.theme.mode.bg};
  padding: 80px 0;

  display: flex;
  flex-direction: column;

  .ExplorerContainer {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${props => props.theme.screens.mbB} {
      padding: 0;
    }

    &__details {
      max-width: 450px;
      margin-bottom: 60px;

      .ExplorerDetails {
        text-align: center;

        @media ${props => props.theme.screens.mbB} {
          padding: 0 20px;
        }

        &__title {
          margin-bottom: 40px;

          h2 {
            font-size: ${props => props.theme.sizes.ftXI}px;
            font-weight: 500;
            color: ${props => props.theme.theme.mode.font};

            @media ${props => props.theme.screens.mbB} {
              font-size: ${props => props.theme.sizes.ftXIm}vw;
            }
          }
        }

        &__description {
          p {
            font-size: ${props => props.theme.sizes.ftR}px;
            color: ${props => props.theme.theme.mode.font};
            line-height: ${props => props.theme.sizes.ftR + 10}px;
            text-transform: uppercase;

            @media ${props => props.theme.screens.mbB} {
              font-size: ${props => props.theme.sizes.ftRm}vw;
              line-height: ${props => props.theme.sizes.ftRm + 2}vw;
            }
          }
        }
      }
    }

    &__list {
      width: 90%;

      @media ${props => props.theme.screens.mbB} {
        width: 100%;
      }

      .ExplorerList {
        &__slider {
          margin-bottom: 60px;

          &--found {
            height: 38.9vw;
            max-height: 498px;

            display: flex;
            justify-content: center;
            align-items: center;

            @media ${props => props.theme.screens.tb} {
              height: 58.9vw;
            }

            @media ${props => props.theme.screens.mbB} {
              height: 108.9vw;
              padding: 0 10px;
            }
          }

          &--alert {
            font-size: ${props => props.theme.sizes.ftM}px;
            color: ${props => props.theme.theme.mode.font};
          }

          .slick-track {
            @media ${props => props.theme.screens.mbB} {
              padding: 0 20px;

              display: flex;
            }

            .slick-slide {
              >div {
                >div {
                  height: 38.9vw;
                  max-height: 498px;
                  padding: 0 20px;
                  cursor: pointer;

                  @media ${props => props.theme.screens.tb} {
                    height: 58.9vw;
                  }

                  @media ${props => props.theme.screens.mbB} {
                    height: 108.9vw;
                    padding: 0 10px;
                  }

                  .CardExplorerContainer {
                    height: 100%;

                    &:hover {
                      img {
                        transform: scale(1.1);
                      }
                    }

                    &__image {
                      height: 100%;

                      display: flex;
                      justify-content: center;
                      align-items: center;

                      img {
                        height: 100%;
                        transition: all ease 0.5s;
                        object-fit: cover;
                        object-position: center;
                      }
                    }
                  }
                }
              }
            }
          }

          .slick-dots {
            top: 10px;
            bottom: unset;

            li {
              button {
                &::before {
                  font-size: ${props => props.theme.sizes.ftS}px;
                  color: ${props => props.theme.theme.green};
                }
              }
            }
          }
        }

        &__button {
          display: flex;
          justify-content: center;
        }
      }
    }

    
  }
`;

export const ExplorerSearchContainer = styled.div`
  width: 90%;
  padding: 0 20px;
  margin-bottom: 20px;
  
  @media ${props => props.theme.screens.mbB} {
    padding: 0 10px;
  }

  .ExplorerSearchContainer {
    display: flex;
    gap: 20px;
    align-items: center;

    @media ${props => props.theme.screens.mbB} {
      flex-direction: column;
    }

    &__type,
    &__technologies {
      @media ${props => props.theme.screens.mbB} {
        width: 100%;
      }

      select {
        @media ${props => props.theme.screens.mbB} {
          width: 100%;

          display: block;
        }
      }
    }
  }
`;
