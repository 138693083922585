// LIBs
import { ReactNode } from "react";

// STYLEs
import { ContentDashLight } from "./styles";

interface IContentDashLightLayoutProps {
  children: ReactNode;
}

export function ContentDashLightLayout({ children }: IContentDashLightLayoutProps) {
  return (
    <ContentDashLight>
      {children}
    </ContentDashLight>
  );
}
