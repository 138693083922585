// LIBs
import styled from "styled-components";

export const Form = styled.div`
  .SettingsForm {
    display: flex;
    flex-direction: column;
    gap: 40px;

    div {
      margin: 0;

      p {
        font-size: ${props => props.theme.sizes.ft}px;
        color: ${props => props.theme.theme.mode.font};
        font-weight: 200;
        margin-bottom: 20px;
        transition: all ease 0.5s;

        @media ${props => props.theme.screens.mbB} {
          font-size: ${props => props.theme.sizes.ftm}vw;
        }
      }
    }
  }
`;
