export const screens = {
  mbS: "only screen and (max-width: 375px)", // Mobile Small
  mb: "only screen and (max-width: 400px)", // Mobile
  mbB: "only screen and (max-width: 450px)", // Mobile Big
  tb: "only screen and (max-width: 768px)", // Tablet
  dsS: "only screen and (max-width: 1024px)", // Desktop Small
  ds: "only screen and (max-width: 1280px)", // Desktop
  dsB: "only screen and (max-width: 1440px)", // Desktop Big

  mbV: "only screen and (max-width: 580px)", // Mobile Variant
  tbV: "only screen and (max-width: 991px)", // Tablet Variant
  dsV: "only screen and (max-width: 1680px)", // Desktop Variant
};